import React from "react";
import EsstartRoutes from "./routes";
import { Toaster } from "react-hot-toast";
import "./App.css";
import "./utils.css";

const App = () => {
  return (
    <div className='app app__wrapper'>
      <EsstartRoutes />
      <Toaster
        containerStyle={{
          top: 75,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      />
    </div>
  );
};

export default App;
