import { emailPattern, phonePattern } from "../utils/constant";

interface IEvent {
	eventCode?: string;
}

interface IErrorEvent {
	eventCode?: string;
}

interface ISignup {
	email?: any;
	phone?: any;
	name?: any;
	tncAccepted?: any;
}

interface IErrorSignup {
	email?: any;
	phone?: any;
	name?: any;
	tncAccepted?: any;
}

interface ILogin {
	email?: string;
	name?: string;
}

interface IErrorLogin {
	email?: string;
	name?: string;
}

export const EventValidate = (values: IEvent) => {
	const errors = {} as IErrorEvent;

	if (!values.eventCode) {
		errors.eventCode = "Please enter activity code";
	}

	return errors;
};

export const SignupValidate = (values: ISignup) => {
	const errors = {} as IErrorSignup;
	if (!values?.phone) {
		if (!values.email) {
			errors.email = "Please enter email";
		} else if (values?.email && !emailPattern.test(values.email)) {
			errors.email = "Valid Email Required";
		}
	} else if (!values?.email) {
		if (!values.phone) {
			errors.phone = "Please enter phone number";
		}
		// else if (values?.phone && (values.phone.length < 12 || values.phone.length > 12)) {
		//   errors.phone = 'Phone number should be of 10 digits';
		// }
		// else if (values?.phone && !phonePattern.test(values.phone)) {
		//   errors.phone = 'Phone number should only contians numeric values';
		// }
	}
	if (!values?.name?.trim()) {
		errors.name = "Please enter the name";
	}
	if (
		window.location.pathname.includes("phone-signup") &&
		(!values.phone || values?.phone?.length < 9)
	) {
		errors.phone = "Please enter a valid phone number";
	}
	if (
		window.location.pathname.includes("phone-signup") &&
		!values?.tncAccepted
	) {
		errors.tncAccepted = "Please accept to proceed";
	}
	return errors;
};

export const LoginValidate = (values: ILogin) => {
	const errors = {} as IErrorLogin;
	if (!values.email) {
		errors.email = "Please enter email";
	} else if (!emailPattern.test(values.email)) {
		errors.email = "Valid Email Required";
	}

	return errors;
};
