import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import GoogleIcon from "../../assets/image/Google.svg";
import FacebookIcon from "../../assets/image/Facebook.svg";
// import PhoneIcon from "../../assets/image/noun-sms.svg";
// import EmailIcon from "../../assets/image/email-otp.svg";
import WarningIcon from "../../assets/image/warning-icon.svg";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setCookie } from "../../utils/Cookies";
import { appMapping } from "../../constants";
import PhoneIcon from "../../assets/image/phoneIcon";
import EmailIcon from "../../assets/image/emailIcon";

const StyleContainer = styled(Box)`
	.signupBox {
		border: 1px solid #d2d2d2;
		padding: 26px 6px;
		position: relative;
		margin-top: 20px;
		width: 100%;
		margin: 30px auto 0 auto;
		justify-content: center;
		background-color: #fff;
		border-radius: 4px;
	}
	.label_login {
		color: #ff7200;
		background-color: #fff;
		text-align: center;
		position: absolute;
		padding: 6px 31px;
		border-radius: 4px;
		top: -19px;
		left: 0;
		right: 0;
		width: fit-content;
		margin: 0 auto;
		border: 1px solid #ff7200;
	}
	.socialMedia_option .googleLink,
	.socialMedia_option .facebookLink {
		width: 80px;
	}
	.socialMedia_option .facebookLink {
		margin-left: 18px;
		cursor: pointer;
	}
	.googleLink,
	.facebookLink,
	.option-item {
		cursor: pointer;
	}
	.option-item {
		border-radius: 4px;
		padding: 10px 20px;
		width: 100%;
		border: 1px solid #c8c8c8;

		svg {
			height: 20px;
			width: 20px;
		}

		p {
			margin: 0 0 0 0.5rem;
			font-size: 1rem;
			line-height: 1.188rem;
			color: #202020;
		}
	}
	.disable_option {
		border: 1px solid #c8c8c8;
	}
	.disable_option p {
		color: #20202094;
	}
	.disable_option img {
		opacity: 0.6;
	}
	img {
		width: 20px;
	}

	@media (min-width: 600px) and (max-width: 650px) {
		.signupBox p.MuiTypography-root {
			font-size: 13px;
		}
	}
	@media (max-width: 415px) {
		.socialMedia_option .googleLink,
		.socialMedia_option .facebookLink {
			width: 40px;
		}
		.socialMedia_option .facebookLink {
			margin-left: 5px;
		}
	}
	@media (max-width: 425px) {
		.signupBox {
			padding: 26px 6px;
		}
		.signupBox p.MuiTypography-root {
			font-size: 16px;
		}
	}
`;

interface IProps {
	isEmail?: boolean;
	isPhone?: boolean;
	tncCheck?: boolean;
}

export const Login = ({ isEmail, isPhone }: IProps) => {
	document.title = "Esstart.com > Guest Login";
	const navigate = useNavigate();
	const params = useParams();
	const eventCodeExpiry = new Date(new Date().getTime() + 60 * 60 * 1000 * 3);
	params?.id &&
		setCookie("eventCode", params?.id, {
			expires: eventCodeExpiry,
			// domain: getDomain()
		});
	const { tncCheck } = useSelector((store: any) => store?.event);
	const { eventVerfiyData } = useSelector((store: any) => store?.event);

	const onGoogleLogin = () => {
		window.open(process.env.REACT_APP_GOOGLE_LOGIN_URL, "_self");
	};

	const onFaceBookLogin = () => {
		window.open(process.env.REACT_APP_FACEBOOK_LOGIN_URL, "_self");
	};
	const showToast = () =>
		toast("Please accept the terms and conditions", {
			icon: <img src={WarningIcon} alt='alert' />,
			className: "alert-toast",
		});

	const btnClickHandler = (callbackFn: any) => {
		// eslint-disable-next-line
		tncCheck ? callbackFn() : showToast();
	};

	//@ts-ignore
	const currentProductName = appMapping[eventVerfiyData?.distinctName];

	return (
		<>
			<StyleContainer>
				<Box>
					<div className='flex-column align-items-center gap-1 signupBox optOption'>
						{eventVerfiyData?.[currentProductName?.keyName]?.enabledAuthenticationTypes?.length ? (
							<>
								{eventVerfiyData?.[currentProductName?.keyName]?.enabledAuthenticationTypes?.includes("sms") ? (
									<div
										className={`${isPhone ? "disable_option" : ""} flex-row align-items-center option-item`}
										onClick={() => btnClickHandler(() => navigate(`/phone-signup/${params.id}`))}
									>
										<PhoneIcon themeColor={currentProductName?.themeColor} />
										<p>Text One Time Code</p>
									</div>
								) : (
									<></>
								)}
								{eventVerfiyData?.[currentProductName?.keyName]?.enabledAuthenticationTypes?.includes("email") ? (
									<div
										className={`${isEmail ? "disable_option" : ""} flex-row align-items-center option-item`}
										onClick={() => btnClickHandler(() => navigate(`/email-signup/${params.id}`))}
									>
										<EmailIcon themeColor={currentProductName?.themeColor} />
										<p>Email One Time Code</p>
									</div>
								) : (
									<></>
								)}
							</>
						) : (
							<></>
						)}
						<div className='googleLink flex-row align-items-center option-item' onClick={() => btnClickHandler(onGoogleLogin)}>
							<img src={GoogleIcon} alt='Google' width='20' />
							<p>Enter with Google</p>
						</div>
						<div className='facebookLink flex-row align-items-center option-item' onClick={() => btnClickHandler(onFaceBookLogin)}>
							<img src={FacebookIcon} alt='Facebook' width='20' />
							<p>Enter with Facebook</p>
						</div>
					</div>
				</Box>

				{/* <Grid container spacing={2}>
          <Grid item xs={12} margin='50px 0 0 0' padding='0 !important'>
            <Box
              className='googleLink'
              display='flex'
              alignItems='center'
              justifyContent='center'
              border='1px solid #C8C8C8'
              borderRadius='20px'
              padding='10px'
              width='85%'
              margin='40px auto 30px auto'
            >
              <img src={PhoneIcon} alt='Email' />
              <Typography
                variant='body1'
                marginLeft={1}
                fontSize='16px'
                lineHeight='19px'
                color='#202020'
                component='p'
              >
                Upload Photo Via SMS
              </Typography>
            </Box>
          </Grid>
        </Grid> */}
			</StyleContainer>
		</>
	);
};
