import React from "react";
import "./SessionExpired.css";
import EventDetail from "../eventDetail";
import { getCookie } from "../../utils/Cookies";

export const SessionExpired = () => {
	const activityCode = getCookie("eventCode");

	const handleRedirectHome = () => {
		if (!!activityCode) {
			window.location.href = `${window.location.origin}/event/${activityCode}`;
		} else {
			window.location.href = `${window.location.origin}`;
		}
	};

	return (
		<>
			{process.env.REACT_APP_CUSTOM_NODE_ENV !== "production" && (
				<button
					onClick={() => {
						alert(
							`${getCookie("cookie1")}---------------${localStorage.getItem(
								"local1"
							)}---------------${sessionStorage.getItem(
								"session1"
							)}-----------------${getCookie(
								"refreshToken"
							)}-----------------${getCookie("accessToken")}`
						);
					}}
				>
					Alert btn
				</button>
			)}

			<EventDetail showHide={false}>
				<div className='expired_session_page'>
					<div className='error_box'>
						<p className='error_title'>Your Session has EXPIRED !</p>
						<p className='error_desc'>Your page didn't respond.</p>
						<p className='error_desc mt-2'> We suggest you back to Home</p>
						<button className='session-expire-btn' onClick={handleRedirectHome}>
							BACK TO HOME
						</button>
					</div>
				</div>
			</EventDetail>
		</>
	);
};
