import React from "react";
import { useNavigate } from "react-router-dom";
import {
	eventRelation,
	eventVerify,
	redirectToPlay,
	refreshAuthToken,
} from "../redux/event";
import { useDispatch } from "react-redux";
import { getCookie } from "../utils/Cookies";

interface IProps {
	children?: any;
}

export const RouteInterceptor = ({ children }: IProps) => {
	const navigate = useNavigate();

	const eventCode = getCookie("eventCode");
	const dispatch = useDispatch<any>();
	const accessToken = getCookie("accessToken");
	const refreshToken = getCookie("refreshToken");

	const windowUrl = window.location.search;
	const params = new URLSearchParams(windowUrl);
	const queryStringEventCode = params.get("eventCode");

	const getEventRelationAndNavigate = async () => {
		const data = await dispatch(eventRelation({ eventCode }));
		if (data?.payload?.status === "success")
			dispatch(redirectToPlay(eventCode));
		else if (
			data.payload.message === "SECRET CODE REQUIRED" &&
			window.location.pathname !== "/verify-guest"
		)
			navigate("/verify-guest");
	};

	if (!accessToken && refreshToken) {
		(async () => {
			const res = await dispatch(
				refreshAuthToken({ refreshToken: refreshToken })
			);
			if (res?.payload?.status === "success")
				await getEventRelationAndNavigate();
		})();
	} else if (accessToken && eventCode) {
		(async () => {
			await getEventRelationAndNavigate();
		})();
	}

	React.useEffect(() => {
		if (queryStringEventCode) {
			dispatch(eventVerify({ eventCode: queryStringEventCode }));
		} else if (!eventCode) {
			// navigate('/event'); // ? removed navigate('/event') due to issue in prod of random redirection to homepage
		} else {
			dispatch(eventVerify({ eventCode }));
		}
	}, [eventCode, dispatch, queryStringEventCode, navigate]);

	return <div>{children}</div>;
};
