import React, { useState, useEffect, useRef } from "react";
import { styled, Box } from "@mui/system";
import {
	Button,
	Container,
	Divider,
	Typography,
	LinearProgress,
} from "@mui/material";
import EventDetail from "../eventDetail";
import { useNavigate, useParams } from "react-router-dom";
import { getCookie, SetAuthCookie } from "../../utils/Cookies";
import {
	confirmAccount,
	redirectToPlay,
	resendCode,
	clearMessage,
	eventRelation,
	updateUserDetails,
} from "../../redux/event";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/react";
import { appMapping } from "../../constants";

const StyleContainer = styled(Container)`
	.otp-form {
		padding: 0;
	}
	.otp-container {
		text-align: left;
		font: normal normal 400 23px/28px Montserrat;
		letter-spacing: 0px;
		color: #202020;
		opacity: 1;
		// border-style: hidden;
		border: none;
		background: #ffffff 0% 0% no-repeat padding-box;
		width: 45px !important;
		height: 44px;
		border-top: none;
		border-left: none;
		border-right: none;
	}
	.otp-focus-style {
		border-style: none;
		border: none;
		outline: 0em;
		background: #ffffff 0% 0% no-repeat padding-box;
	}
	.click-here {
		text-decoration: none;
		color: #009fb7;
		font-size: 16px;
	}
	.main-input {
		display: flex;
		justify-content: center;
	}
	.main-input > :nth-of-type(1) {
		//border: 1px solid #000 !important;
	}
	.otp-inp::-webkit-outer-spin-button,
	.otp-inp::-webkit-inner-spin-button {
		display: none;
	}
	.otp-inp {
		display: inline-block;
		margin: 0 auto;
		border: none;
		padding: 0;
		width: 6ch;
		background: repeating-linear-gradient(
				90deg,
				black 0,
				black 1ch,
				transparent 0,
				transparent 1.5ch
			)
			0 100%/ 10ch 2px no-repeat;
		font: 3rem "Ubuntu Mono", monospace !important;
		letter-spacing: 0.5ch;
	}
	.otp-inp:focus {
		outline: none;
		color: black;
	}
	.progress-bar .css-afznn1-MuiLinearProgress-root {
		background-color: #e5e5e5;
	}
	.progress-bar .css-5xe99f-MuiLinearProgress-bar1 {
		background-color: #ff7200;
	}
	.main-progressbar {
		margin-top: 15px;
		position: relative;
	}
	.process-persentage {
		position: absolute;
		margin: auto;
		top: 1px;
		font-size: 11px;
		color: #fff;
		font-weight: 600;
		text-align: center;
		left: 10px;
		right: 0;
	}
	.process-box {
		width: 100%;
		height: 200px;
		// background-color: #EFEFEF;
		margin: auto;
	}
`;

const OTP_LENGTH = 4;
const VerifyOtp = () => {
	const event: any = useSelector((state: any) => state.event);
	const eventCode: any = Cookies.get("eventCode");
	const getEmail = localStorage.getItem("email");
	const getPhone = localStorage.getItem("phone");
	const { id } = useParams();
	const [otp, setOtp] = useState<any>(id ? id : "");
	const [isCount, setIsCount] = useState<number>(0);
	const [isClick, setIsClick] = useState<boolean>(false);
	const dispatch = useDispatch<any>();
	const navigate = useNavigate();

	const handleOtpChange = (otp: any) => {
		setOtp(otp.target.value);
		if (otp.target.value.length === 4) return false;
	};

	const otpInputRef = useRef(null);

	const guestUsername = localStorage.getItem("username");
	const guestName = localStorage.getItem("guestName");
	const session = Cookies.get("session");
	const [progress, setProgress] = useState(0);
	const [timer, setTimer] = React.useState(0);

	useEffect(() => {
		timer < 30 && setTimeout(() => setTimer(timer + 1), 1000);
	}, [timer]);

	useEffect(() => {
		const progressTimer = setInterval(() => {
			setProgress((prevProgress) => prevProgress + 6);
		}, 2000);

		return () => {
			clearInterval(progressTimer);
		};
	}, []);

	useEffect(() => {
		if (!session) {
			dispatch(clearMessage());
			navigate(`/signup/${eventCode}`);
		}
	}, [session, dispatch]);

	useEffect(() => {
		if (id) {
			if (id.length === OTP_LENGTH && guestUsername && session) {
				setOtp(otp);
				dispatch(
					confirmAccount({
						username: guestUsername,
						passCode: id,
						session: session,
					})
				);
			}
		}
		//@ts-ignore
		otpInputRef?.current?.focus();
	}, [id, dispatch, otp]);

	useEffect(() => {
		if (
			event?.verifyStatus &&
			(event?.verifyStatus === 401 || event?.verifyStatus === 400)
		) {
			dispatch(clearMessage());
			navigate(`/signup/${eventCode}`);
			toast("Invalid OTP", {
				icon: "❗",
				className: "alert-toast",
			});
		}
	}, [event?.verifyStatus, dispatch]);

	const handleSetTimeout = () => {
		const totalCount = isCount + 1;
		setIsCount(totalCount);
		const newSession = Cookies.get("session");
		if (!newSession) {
			dispatch(clearMessage());
			navigate(`/signup/${eventCode}`);
		}
		if (isCount < 3 && newSession) {
			setTimeout(() => {
				const finalOTP = otp;
				const accessToken = getCookie("accessToken");
				if (!accessToken) {
					dispatch(
						confirmAccount({
							username: guestUsername,
							passCode: finalOTP ? finalOTP : "1111",
							session: newSession,
						})
					);
				}
			}, 175000);
		}
	};

	useEffect(() => {
		const newSessionGet = Cookies.get("session");
		if (!newSessionGet) {
			dispatch(clearMessage());
			navigate(`/signup/${eventCode}`);
		}
		setTimeout(() => {
			if (!isClick && newSessionGet) {
				const accessToken = getCookie("accessToken");
				if (!accessToken) {
					dispatch(
						confirmAccount({
							username: guestUsername,
							passCode: otp ? otp : "1111",
							session: newSessionGet,
						})
					);
				}
			}
		}, 175000);
	}, [isClick]);

	const handleReset = (e: any) => {
		e.preventDefault();
		const userId = getCookie("userId");
		if (userId) {
			const type = getEmail ? "email" : "phone";
			dispatch(resendCode({ userId: userId, type: type }));
		}
	};

	useEffect(() => {
		if (eventCode && event?.confirmationData) {
			const confirmData = event?.confirmationData;
			if (
				confirmData?.ChallengeName === "CUSTOM_CHALLENGE" &&
				confirmData?.Session
			) {
				Cookies.set("session", confirmData?.Session);
				handleSetTimeout();
			} else if (confirmData?.AuthenticationResult) {
				SetAuthCookie(confirmData?.AuthenticationResult);
				if (guestName) {
					dispatch(
						updateUserDetails({
							name: guestName,
							...(!!event?.guestRelationId
								? { relationId: event?.guestRelationId }
								: {}),
						})
					);
				}
				(async () => {
					const data = await dispatch(
						eventRelation({ eventCode, ...(guestName ? { guestName } : {}) })
					);
					if (data?.payload?.status === "success")
						dispatch(redirectToPlay(eventCode));
					else if (
						data.payload.message === "SECRET CODE REQUIRED" &&
						window.location.pathname !== "/verify-guest"
					)
						navigate("/verify-guest");
					Sentry.captureMessage(
						JSON.stringify({
							page: "Enter OTP eventRelation API check",
							eventCode,
							confirmationData: event?.confirmationData,
							event,
							responseFromApi: data,
						})
					);
				})();
			}
		}
	}, [event?.confirmationData, dispatch, eventCode]);

	const handleConfirmAccount = async (otp: string) => {
		if (otp.length === OTP_LENGTH && guestUsername && session) {
			setIsClick(true);
			const res = await dispatch(
				confirmAccount({
					username: guestUsername,
					passCode: otp,
					session: session,
				})
			);
		}
	};

	//@ts-ignore
	const currentProductName = appMapping[event?.eventVerfiyData?.distinctName];

	return (
		<>
			<StyleContainer>
				<EventDetail showHide={false}>
					<Container sx={{ padding: 0 }}>
						{timer < 28 && progress < 100 ? (
							<>
								{" "}
								<Box component='div' className='process-box'>
									<iframe
										src='https://player.vimeo.com/video/787348696?h=eee46233aa&amp;badge=0&amp;autopause=0&amp;app_id=58479&amp;autoplay=1&amp;muted=1?playsinline=0&amp;controls=false'
										width='100%'
										height='100%'
										allow='autoplay'
										frameBorder='0'
										allowFullScreen
										title='Welcome Video'
									></iframe>
								</Box>
								<Box className='main-progressbar'>
									<Box className='progress-bar'>
										<LinearProgress
											variant='determinate'
											value={progress}
											sx={{ height: "16px", borderRadius: "12px" }}
										/>
									</Box>
									<p className='process-persentage'>{`${Math.round(
										progress
									)}%`}</p>
								</Box>
								<Typography
									component='p'
									fontSize='18px'
									textAlign='center'
									marginTop='9px'
								>
									Processing...
								</Typography>
							</>
						) : (
							<></>
						)}
						<Divider style={{ margin: "15px auto", borderColor: "#D2D2D2" }} />

						<Typography component='p' textAlign='center' fontSize='14px'>
							You've entered <b>{getPhone ? getPhone : getEmail}</b>.
						</Typography>
						<Typography component='p' textAlign='center' fontSize='14px'>
							<a
								href={
									getPhone
										? `${window.location.origin}/phone-signup/${eventCode}`
										: `${window.location.origin}/email-signup/${eventCode}`
								}
								className='click-here'
							>
								CLICK HERE
							</a>{" "}
							to change
						</Typography>
						<form
							className='otp-form'
							onSubmit={(e) => {
								e.preventDefault();
								handleConfirmAccount(otp);
							}}
						>
							<Typography
								component='p'
								color='#202020'
								textAlign='center'
								marginTop='40px'
								marginBottom='40px'
								fontSize='18px'
							>
								Enter 4 digit code
							</Typography>

							<Box
								component='div'
								className='main-input'
								marginBottom='8px'
								textAlign='center'
							>
								<>
									<input
										className='otp-inp'
										type='number'
										maxLength={OTP_LENGTH}
										onChange={handleOtpChange}
										value={otp}
										ref={otpInputRef}
										onInput={(e) =>
											//@ts-ignore
											(e.target.value = e.target.value.slice(0, 4))
										}
									/>
								</>
							</Box>
							<Typography
								component='p'
								textAlign='center'
								fontSize='14px'
								margin='8px 0 30px'
							>
								<span>Didn't receive the {getPhone ? "SMS" : "email"}?</span>{" "}
								<span className='click-here' onClick={(e) => handleReset(e)}>
									RESEND {getPhone ? "SMS" : "EMAIL"}
								</span>
							</Typography>

							<Button
								type='submit'
								variant='contained'
								style={{
									margin: "auto",
									display: "flex",
									background: currentProductName?.themeColor,
									borderRadius: "20px",
									fontSize: 14,
									lineHeight: "18px",
									height: "40px",
									width: "198px",
									boxShadow: "none",
								}}
							>
								PROCEED
							</Button>
						</form>
					</Container>
				</EventDetail>
			</StyleContainer>
		</>
	);
};

export default VerifyOtp;
