import React from "react";

const EmailIcon = (props: any) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='14.891' height='14.891' viewBox='0 0 14.891 14.891'>
		<g id='noun-email-1070730' transform='translate(-98 -28)'>
			<path
				id='Path_489'
				data-name='Path 489'
				d='M112.295,32.2c-.6-.463-5.84-3.789-6.072-3.921a1.216,1.216,0,0,0-1.555,0c-.232.132-5.476,3.458-6.072,3.921a1.429,1.429,0,0,0-.6,1.059v9.629h14.891V33.278a1.45,1.45,0,0,0-.6-1.075Zm-.016,9.282a1.892,1.892,0,0,1,.579,1.34,1.5,1.5,0,0,0-.579-1.042c-.579-.463-5.791-3.789-6.022-3.921-.017-.017-.033-.017-.05-.033s-.033-.017-.05-.033a1.765,1.765,0,0,0-.662-.232,1.817,1.817,0,0,0-.695.232c-.033.017-.05.033-.083.05-.232.132-5.443,3.458-6.022,3.921a1.714,1.714,0,0,0-.645,1.042,2.02,2.02,0,0,1,.645-1.34c.546-.43,4.98-3.276,5.874-3.822-1.886-1.34-4.765-3.855-5.262-4.087a3.056,3.056,0,0,0-1.208-.3,1.651,1.651,0,0,1,.5-.645c.6-.463,5.84-3.789,6.072-3.921a1.835,1.835,0,0,1,.778-.281,1.988,1.988,0,0,1,.778.281c.232.132,5.476,3.458,6.072,3.921a1.651,1.651,0,0,1,.5.645,2.938,2.938,0,0,0-1.191.3c-.513.232-3.359,2.713-5.228,4.087.827.513,5.361,3.408,5.907,3.838Zm-5.708-8.819-.794-.794v3.077h-.662V31.872l-.794.811-.5-.513,1.638-1.655L107.1,32.17Z'
				transform='translate(0)'
				fill={props.themeColor}
			/>
		</g>
	</svg>
);

export default EmailIcon;
