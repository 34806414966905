import { useState, useEffect, useRef, SyntheticEvent } from "react";
import { useFormik } from "formik";
import PhoneInput from "react-phone-number-input";
import toast from "react-hot-toast";
import giftIcon from "../../assets/image/gift-icon.svg";
import coffeeBg from "../../assets/image/bg4.png";
import coffeeBanner from "../../assets/image/coffee-bg-v5.png";
import buyNowBanner from "../../assets/image/Buy-now-w-coffee2.png";
import "./style.css";

const OTP_LENGTH = 4;
const LAST_PAGE_NUMBER = 6;

export const Tradeshow5Aug24 = () => {
	const [token, setToken] = useState("");
	const [showPhoneNumber, toggleShowPhoneNumber] = useState(false);
	const [errors, setErrors] = useState<any>({});

	const phoneRef = useRef(null);
	const verifyBtnRef = useRef(null);
	const nextBtnRef = useRef(null);

	const handleKeyDown = (e: any, nextElementRef: any) => {
		if (e.key === "Enter" || e.key === "Return") {
			e.preventDefault();
			if (nextElementRef?.current?.nodeName === "BUTTON")
				nextElementRef?.current?.click();
			else nextElementRef?.current?.focus();
		}
	};

	const formik = useFormik({
		initialValues: {
			name: "",
			phoneNumber: "",
			email: "",
			otp: "",
			pageNum: 1,
			businessType: [],
			businessName: "",
		},
		onSubmit: () => {},
	});

	const handleOtpChange = (otp: any) => {
		formik.setFieldValue("otp", otp.target.value);
		if (otp.target.value.length === 4) return false;
	};

	const businessTypeArr = [
		"DJ",
		"Photobooth",
		"Planner",
		"Club DJ",
		"Other",
		"All of above",
	];

	const businessTypeChangeHandler = (str: string) => {
		//@ts-ignore
		const isTypeExist = formik.values.businessType.includes(str);
		const newValue = isTypeExist
			? formik.values.businessType.filter((i) => i !== str)
			: [...formik.values.businessType, str];

		formik.setFieldValue("businessType", newValue);
	};

	const saveDetails = async (param: any) => {
		const url = `${process.env.REACT_APP_API_URL}/common/trade-show-form`;
		const config = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(param),
		};
		try {
			const res = await fetch(url, config);
			const data = await res.json();
			if (data?.data?.token) {
				setToken(data?.data?.token);
			}

			if (data?.message === "Already Registered") {
				toast("You are already registered with Esstart", {
					icon: "❗",
					className: "alert-toast",
				});
				formik.setFieldValue("pageNum", LAST_PAGE_NUMBER);
			}
			if (data?.message === "OTP DOES NOT MATCH") {
				toast("Invalid OTP", {
					icon: "❗",
					className: "alert-toast",
				});
				return -1;
			}
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		saveDetails({ warmup: true });
	}, []);

	const checkForRequiredValues = () => {
		switch (formik.values.pageNum) {
			case 2:
				if (!formik.values.name?.trim()) {
					setErrors({ name: "Please fill the name" });
				} else if (!formik.values.phoneNumber) {
					setErrors({
						phoneNumber: "Please fill the phone number",
					});
				} else if (formik.values.phoneNumber.length < 9) {
					setErrors({
						phoneNumber: "Please fill the valid phone number",
					});
				} else {
					setErrors({});
					return 1;
				}
				break;
			case 3:
				if (!formik.values.email?.trim()) {
					setErrors({
						email: "Please fill the email",
					});
				} else {
					setErrors({});
					return 1;
				}
				break;
			case 4:
				if (!formik.values.businessName?.trim()) {
					setErrors({
						businessName: "Please fill the business/ group name",
					});
				} else if (formik.values.businessType.length === 0) {
					setErrors({
						businessType: "Please select at least one",
					});
				} else {
					setErrors({});
					return 1;
				}
				break;
			default:
				setErrors({});
		}
	};

	const nextBtnClickHandler = async () => {
		const res = checkForRequiredValues();
		if (res !== 1) {
			return;
		}
		const isUsaNumber = formik.values.phoneNumber.startsWith("+1");
		let payload;
		switch (formik.values.pageNum) {
			case 2:
				payload = {
					name: formik.values.name,
					phoneNumber: formik.values.phoneNumber,
				};
				break;
			case 3:
				payload = {
					token: token,
					email: formik.values.email,
				};
				break;
			case 4:
				payload = {
					token: token,
					businessType: formik.values.businessType,
					businessName: formik.values.businessName,
					...(isUsaNumber ? { sendOtp: true } : {}),
				};
				break;
		}
		if (!isUsaNumber && formik.values.pageNum === 4) {
			formik.setFieldValue("pageNum", formik.values.pageNum + 2);
		} else {
			formik.setFieldValue("pageNum", formik.values.pageNum + 1);
		}

		saveDetails(payload);
	};
	const backBtnClickHandler = () => {
		formik.setFieldValue("pageNum", formik.values.pageNum - 1);
	};

	const sendOtpAgain = async () => {
		toast("OTP sent successfully!", {
			className: "success-toast",
		});
		saveDetails({
			sendOtp: true,
			token,
			changedPhoneNumber: formik.values.phoneNumber,
		});
	};

	const verifyOtpHandler = async () => {
		if (!formik.values.otp || formik.values.otp.length < 4) {
			setErrors({ otp: "Please enter the OTP" });
			return;
		}
		const payload = {
			token: token,
			otp: Number(formik.values.otp),
		};
		formik.setFieldValue("pageNum", formik.values.pageNum + 1);
		const res = await saveDetails(payload);
		if (res === -1) {
			formik.setFieldValue("pageNum", formik.values.pageNum);
		}
	};

	return (
		<div
			className='tradeshow-page'
			style={{
				backgroundImage: `url(${coffeeBg})`,
			}}
		>
			<div className='flex-column justify-content-between h-full pos-relative'>
				{/* -------------------------------------------------------------- 
                                  PAGE 1 
        --------------------------------------------------------------*/}

				<div
					className={`page flex-grow-1 ${
						formik.values.pageNum === 1 ? "active-page" : ""
					}`}
				>
					<div className='flex-column justify-content-center gap-3rem align-items-center h-full'>
						<a
							href='https://guest.esstart.com/event/26.hwu8'
							className='glowing-card-link'
							target='_blank'
							rel='noopener noreferrer'
						>
							<div className='glowing-card'>
								<div>Click here to try</div>
								<div>Instant Spotlight</div>
							</div>
						</a>

						<p
							className='fw-600 cursor-pointer enter-raffle-text'
							onClick={() =>
								formik.setFieldValue("pageNum", formik.values.pageNum + 1)
							}
						>
							Click here to enter the raffle
						</p>
					</div>
				</div>

				{/* -------------------------------------------------------------- 
                                  PAGE 2 
        --------------------------------------------------------------*/}
				<div
					className={`page flex-grow-1 ${
						formik.values.pageNum === 2 ? "active-page" : ""
					}`}
				>
					<div className='flex-column h-full'>
						<CoffeeBanner />
						<div className='flex-column gap-3rem flex-grow-1'>
							<div>
								<div className='raffle-text'>
									<p className='m-0'>To Enter The Raffle</p>
									<p className='m-0'>Please Complete The Form</p>
								</div>
								<label className='fw-600 fs-20px'>
									Enter Your Name<span className='color-red'>*</span>
								</label>
								<input
									className='form-inp-field'
									onChange={formik.handleChange}
									value={formik.values.name}
									type='text'
									placeholder='Enter Your Name'
									id='name'
									name='name'
									enterKeyHint='next'
									onKeyDown={(e) => handleKeyDown(e, phoneRef)}
								/>
								{errors?.name && (
									<small className='color-red'>{errors.name}</small>
								)}
							</div>
							<div>
								<label className='fw-600 fs-20px'>
									Enter Your Mobile Number<span className='color-red'>*</span>
								</label>
								<PhoneInput
									className='phone-input mt-5px'
									id='phoneNumber'
									placeholder='555-555-5555'
									ref={phoneRef}
									international
									defaultCountry={`${
										Intl.DateTimeFormat().resolvedOptions().timeZone ===
										"Asia/Calcutta"
											? "IN"
											: "US"
									}`}
									value={formik.values.phoneNumber}
									countryCallingCodeEditable={false}
									onChange={(e) => {
										e
											? formik.setFieldValue("phoneNumber", e)
											: formik.setFieldValue("phoneNumber", "");
									}}
									onKeyDown={(e: any) => handleKeyDown(e, nextBtnRef)}
									enterKeyHint='next'
								/>
								<p className='m-0 fw-600'>(We will text you if you win!)</p>
								{errors?.phoneNumber && (
									<small className='color-red'>{errors.phoneNumber}</small>
								)}
							</div>
						</div>
					</div>
				</div>

				{/* -------------------------------------------------------------- 
                                  PAGE 3 
        --------------------------------------------------------------*/}

				<div
					className={`page flex-grow-1 ${
						formik.values.pageNum === 3 ? "active-page" : ""
					}`}
				>
					<div className='flex-column h-full'>
						<CoffeeBanner />
						<div className='flex-column flex-grow-1'>
							<label className='fw-600'>
								<p className='m-0 fs-20px'>
									Email<span className='color-red'>*</span>
								</p>
								<p className='m-0'>
									(which email we can notify you if you are the winner?)
								</p>
							</label>
							<input
								className='form-inp-field'
								onChange={formik.handleChange}
								value={formik.values.email}
								type='email'
								placeholder='Enter your email'
								id='email'
								name='email'
								onKeyDown={(e: any) => handleKeyDown(e, nextBtnRef)}
								enterKeyHint='next'
								tabIndex={-1}
							/>
							{errors?.email && (
								<small className='color-red'>{errors.email}</small>
							)}
						</div>
					</div>
				</div>

				{/* -------------------------------------------------------------- 
                                  PAGE 4 
        --------------------------------------------------------------*/}

				<div
					className={`page flex-grow-1 ${
						formik.values.pageNum === 4 ? "active-page" : ""
					}`}
				>
					<div className='flex-column h-full'>
						<CoffeeBanner />
						<div className='flex-column gap-2rem flex-grow-1'>
							<div>
								<label className='fw-600 fs-20px'>
									Name of your business or group
									<span className='color-red'>*</span>
								</label>
								<input
									className='form-inp-field'
									onChange={formik.handleChange}
									value={formik.values.businessName}
									type='text'
									placeholder='Enter Your Business/ Group name'
									id='businessName'
									name='businessName'
									tabIndex={-1}
								/>
								{errors?.businessName && (
									<small className='color-red'>{errors.businessName}</small>
								)}
							</div>
							<div>
								<label className='fw-600 fs-20px'>
									Type of business<span className='color-red'>*</span>
								</label>
								<div className='flex-row flex-wrap gap-half-rem mt-5px'>
									{businessTypeArr.map((str) => (
										<p
											key={str}
											className={`type-option cursor-pointer ${
												//@ts-ignore
												formik.values.businessType.includes(str)
													? "selected-type-option"
													: ""
											}`}
											onClick={() => businessTypeChangeHandler(str)}
										>
											{str}
										</p>
									))}
								</div>
								{errors?.businessType && (
									<small className='color-red'>{errors.businessType}</small>
								)}
							</div>
						</div>
					</div>
				</div>

				{/* -------------------------------------------------------------- 
                                  PAGE 5 
        --------------------------------------------------------------*/}

				<div
					className={`page flex-grow-1 ${
						formik.values.pageNum === 5 ? "active-page" : ""
					}`}
				>
					<div className='flex-column align-items-center justify-content-center h-full'>
						{showPhoneNumber && (
							<div className='flex-column gap-1 align-items-center mb-2rem'>
								<PhoneInput
									className='phone-input'
									id='phoneNumber'
									placeholder='555-555-5555'
									international
									defaultCountry={`${
										Intl.DateTimeFormat().resolvedOptions().timeZone ===
										"Asia/Calcutta"
											? "IN"
											: "US"
									}`}
									value={formik.values.phoneNumber}
									countryCallingCodeEditable={false}
									onChange={(e) => {
										e
											? formik.setFieldValue("phoneNumber", e)
											: formik.setFieldValue("phoneNumber", "");
									}}
								/>
								<button className='pri-btn send-otp-btn' onClick={sendOtpAgain}>
									Send OTP
								</button>
							</div>
						)}
						<p className='m-0 fs-20px'>
							You've entered {formik.values.phoneNumber}
						</p>
						<p className='m-0 fs-20px'>
							<span
								className='go-back-txt cursor-pointer'
								onClick={() => toggleShowPhoneNumber(true)}
							>
								Click here
							</span>{" "}
							if you want to change the number
						</p>

						<p className='enter-otp-text'>Enter 4 digit code (OTP)</p>
						<input
							className='otp-inp'
							type='number'
							maxLength={OTP_LENGTH}
							onChange={handleOtpChange}
							value={formik.values.otp}
							onInput={(e) =>
								//@ts-ignore
								(e.target.value = e.target.value.slice(0, 4))
							}
							onKeyDown={(e: any) => handleKeyDown(e, verifyBtnRef)}
							enterKeyHint='next'
							tabIndex={-1}
						/>
						{errors?.otp && <small className='color-red'>{errors.otp}</small>}
						<p className='fs-20px fw-600'>
							You are one step away from winning your gift card
						</p>
						<button
							className='verify-otp-btn pri-btn'
							onClick={verifyOtpHandler}
							ref={verifyBtnRef}
						>
							Verify OTP
						</button>
					</div>
				</div>

				{/* -------------------------------------------------------------- 
                                  PAGE 6 
        --------------------------------------------------------------*/}

				<div
					className={`page flex-grow-1 ${
						formik.values.pageNum === LAST_PAGE_NUMBER ? "active-page" : ""
					}`}
				>
					<div className='flex-column align-items-center justify-content-center h-full'>
						<div className='flex-row align-items-center gap-1'>
							<div>
								<img className='gift-icon' src={giftIcon} alt='gift' />
							</div>
							<p className='thank-you-msg'>
								Thank you! You are now registered in the raffle.
							</p>
						</div>

						<div>
							<a
								href={`https://buy.stripe.com/8wMdRq6qc6EC4ne8wL?prefilled_email=${formik.values.email}`}
								target='_blank'
								rel='noopener noreferrer'
							>
								<img className='buy-banner' src={buyNowBanner} alt='buy now' />
							</a>
						</div>
						<div className='w-full'>
							<div className='or-separator flex-row w-full align-items-center'>
								<div className='vertical-line'></div>
								<div className='or-text'>OR</div>
								<div className='vertical-line'></div>
							</div>
						</div>
						<a
							href={`https://calendly.com/cp-at-teamesstart/instant-spotlight-demo-direct?name=${formik.values.name}&email=${formik.values.email}&a1=1&a2=DJX-expo`}
							target='_blank'
							rel='noopener noreferrer'
						>
							<button className='action-btn pri-btn schedule-btn'>
								Schedule a Demo
							</button>
						</a>
					</div>
				</div>

				{formik.values.pageNum === LAST_PAGE_NUMBER ? (
					<></>
				) : (
					<div className='flex-row justify-content-between nav-btns'>
						{formik.values.pageNum === 1 || formik.values.pageNum === 2 ? (
							<div></div>
						) : (
							<button
								className='back-btn pri-btn'
								onClick={backBtnClickHandler}
							>
								Back
							</button>
						)}

						{formik.values.pageNum === 1 ||
						formik.values.pageNum === LAST_PAGE_NUMBER - 1 ? (
							<></>
						) : (
							<button
								className='next-btn pri-btn'
								onClick={nextBtnClickHandler}
								ref={nextBtnRef}
							>
								Next
							</button>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

const CoffeeBanner = () => {
	return (
		<div className='coffee-banner'>
			<img src={coffeeBanner} alt='cofee banner' />
		</div>
	);
};
