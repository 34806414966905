import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export const Footer = () => {
  return (
    <>
      <Box
        position={"absolute"}
        bottom={0}
        width={"100%"}
        className='app-footer'
      >
        <Typography
          paddingY='15px'
          style={{ background: "#F1F1F1" }}
          fontSize='14px'
          lineHeight='18px'
          fontWeight={300}
          color='#202020'
          textAlign='center'
          variant='body2'
          component='p'
        >
          Copyright © {new Date().getFullYear()}{" "}
          <a
            href={process.env.REACT_APP_DEV_WEBSITE_URL_LIVE}
            target='_blank'
            rel='noopener noreferrer'
            className='footer-link'
          >
            Esstart.com
          </a>
          . All rights reserved.
        </Typography>
      </Box>
    </>
  );
};
