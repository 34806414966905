import React from "react";
import { Route, Routes } from "react-router-dom";
import { routes as routesArray } from "./routes";

const SubRoutesEsstart = (routes: any) => {
  if (!routes?.length) return null;
  // checking if subroutes are present
  return getRoutes(routes);
};

const getRoutes = (routes: any) => {
  return routes.map((route: any, i: number) => {
    const { routes } = route;

    return (
      <Route key={i} {...route}>
        {SubRoutesEsstart(routes)}
      </Route>
    );
  });
};

// default routes
export default function EsstartRoutes() {
  // Routing Logic
  return (
    <Routes>{getRoutes(routesArray)}</Routes>
  );
}
