import React from "react";
import { useDispatch } from "react-redux";
import {
	eventRelation,
	redirectToPlay,
	verifyGuestSecurity,
} from "../../redux/event";
import { getCookie } from "../../utils/Cookies";
import EventDetail from "../eventDetail";
import "./style.css";
import toast from "react-hot-toast";

const VerifyUser = () => {
	const dispatch = useDispatch();
	const eventCode = getCookie("eventCode");
	const [date, setDate] = React.useState(null);
	const [secretCode, setSecretCode] = React.useState("");

	const submitForm = async (e: any) => {
		e.preventDefault();
		sessionStorage.setItem("secretCode", secretCode);
		//@ts-ignore
		const data = await dispatch(eventRelation({ eventCode, secretCode }));
		if (data?.payload?.status === "success") {
			//@ts-ignore
			dispatch(redirectToPlay(eventCode));
		} else if (data?.payload?.status === "error") {
			toast("Incorrect data", {
				icon: "❗",
				className: "alert-toast",
			});
		}
	};
	return (
		<div>
			<EventDetail showHide={false}>
				<h3>
					Event is locked 🔒 Secret Code is needed to enter this product. If you
					have access to the host section, Secret Code can be found on the
					product's configuration page.
				</h3>
				<form
					onSubmit={submitForm}
					className='flex-column align-items-center gap-1'
				>
					<label className='flex-column'>
						<span className='span-txt'>Please enter the secret code</span>
						<input
							defaultValue={secretCode}
							placeholder='XXXXXX'
							onChange={(e) => setSecretCode(() => e.target.value)}
						/>
					</label>
					<button className='pri-btn submit-btn'>SUBMIT</button>
				</form>
			</EventDetail>
		</div>
	);
};

export default VerifyUser;
