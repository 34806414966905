import React, { useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { decodeToken } from "react-jwt";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, styled } from "@mui/system";
import {
	socialLoginSuccess,
	redirectToPlay,
	eventRelation,
} from "../../redux/event";
import { Header } from "../header";
import { Footer } from "../footer";
// import Loader from "../../assets/image/Esstart_spinner.gif";
import Loader from "../loader";
import { getCookie, setCookie } from "../../utils/Cookies";

const StyleContainer = styled(Container)`
	padding: 0px;
	.MuiCheckbox-root + .MuiFormControlLabel-label {
		font-size: 15px;
	}
	.MuiDivider-wrapper .MuiChip-root.MuiChip-outlined {
		border-radius: 100%;
		height: 30px;
		width: 30px;
	}
	.MuiDivider-wrapper .MuiChip-label {
		padding: 0px;
	}
	.MuiFormControlLabel-labelPlacementEnd {
		margin: 0px;
	}
	.errorMsg {
		color: red;
	}
	.loader {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		text-align: center;
		background-color: #00000050;
		z-index: 1111;
		align-items: center;
		justify-content: center;
	}
`;

export const SocialLoginSuccess = () => {
	document.title = "Esstart.com > Guest Social Login";
	const location = useLocation();
	const dispatch = useDispatch<any>();
	const accessTokenSocialLogin: any = useSelector((state: any) => state.event);
	const eventCode: any = getCookie("eventCode");
	// const eventCode = localStorage.getItem('eventCode');
	const navigate = useNavigate();

	useEffect(() => {
		if (location?.hash?.match(/access_token=([^&]*)/)?.length) {
			const accessTokenArr = location.hash.match(/access_token=([^&]*)/);
			const accessToken = accessTokenArr?.length ? accessTokenArr[1] : "";
			const decodeJWT: any = decodeToken(accessToken);
			dispatch(
				socialLoginSuccess({ token: accessToken, expiry: decodeJWT?.exp })
			);
		} else {
			navigate(`/welcome/${eventCode}`);
		}
	}, []);

	useEffect(() => {
		if (accessTokenSocialLogin?.accessToken) {
			if (sessionStorage.getItem("gallery") === "yes") {
				window.location.href = `${window.location.origin}/gallery/photos`;
			} else {
				(async () => {
					const data = await dispatch(eventRelation({ eventCode }));
					if (data?.payload?.status === "success")
						dispatch(redirectToPlay(eventCode));
					else if (
						data.payload.data === "EVENT NOT ACTIVE. SECRET CODE REQUIRED" &&
						window.location.pathname !== "/verify-guest"
					)
						navigate("/verify-guest");
				})();
			}
		}
	}, [accessTokenSocialLogin?.accessToken, dispatch, eventCode]);

	return (
		<StyleContainer>
			<Grid container spacing={0} justifyContent='center'>
				<Grid item xs={12} sm={8} md={5}>
					<Header />
					<Box position='relative' style={{ minHeight: "calc(100vh - 75px)" }}>
						<Box sx={{ paddingBottom: "130px" }} paddingX={2}>
							{/* <Typography
                style={{ display: "flex" }}
                className='loader'
                component='div'
              >
                <img src={Loader} alt='Loader' />
              </Typography> */}
							<Loader />
							<Footer />
						</Box>
					</Box>
				</Grid>
			</Grid>
		</StyleContainer>
	);
};
