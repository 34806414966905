import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
	removeCookie,
	setCookie,
	getCookie,
	SetAuthCookie,
} from "../utils/Cookies";

export const eventsSliceName = "event";

const initialState = {
	loading: false,
	secondLoading: false, //specially for refresh auth token
	responseStatus: "",
	accessToken: "",
	eventVerfiyData: null,
	confirmationData: null,
	eventRegisterData: null,
	verifyStatus: 0,
	resendCodeStatus: null,
	tncCheck: true,
	guestRelationId: "",
};

const url = `${process.env.REACT_APP_API_AUTH_URL}/`;
const apiURL = `${process.env.REACT_APP_API_URL}/`;

export const eventVerify = createAsyncThunk(
	"event/verify",
	async (args: any) => {
		const { eventCode, lookup } = args;
		const url = !!lookup
			? `${apiURL}common/v1/guest-event-lookup?lookup=${lookup}`
			: `${apiURL}common/v1/guest-event-lookup?eventCode=${eventCode}`;
		const response = await axios(url);
		// const data = await JSON.stringify(response.data.data)
		// const data = await response.json()

		return response?.data;
	}
);

export const eventRegister = createAsyncThunk(
	"event/register",
	async (args: any) => {
		const { username, eventCode } = args;
		const data = JSON.stringify({
			username: username,
			eventCode: eventCode,
		});

		const config = {
			method: "POST",
			url: `${url}guest-register-passwordless`,
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			data: data,
		};
		const response = await axios(config);
		// const response = await fetch(`${url}guest-register`, config)
		// const json:any = await JSON.stringify(response);
		// const json = await response.json();
		if (response?.data?.data) {
			if (response?.data?.data?.ChallengeName === "CUSTOM_CHALLENGE") {
				// OTP sent
				setCookie(
					"userId",
					response?.data?.data?.ChallengeParameters?.USERNAME
				);
				setCookie("session", response?.data?.data?.Session);
			} else {
				//accesstoken is sent directly, OTP bypassed
				SetAuthCookie(response?.data?.data?.AuthenticationResult);
			}
		}
		return response?.data;
	}
);

export const socialLoginSuccess = createAsyncThunk(
	"auth/socialLoginSuccess2",
	async (args: any) => {
		const { token, expiry } = args;
		setCookie("accessToken", token, { expires: new Date(expiry * 1000) });
		return { token };
	}
);

export const refreshAuthToken = createAsyncThunk(
	"event/refreshAuthToken",
	async (args: any) => {
		const response = await axios.post(`${url}guest/refresh-auth-tokens`, args);
		// const data: any = await JSON.stringify(response);
		if (response?.data?.status === "success") {
			const finalExpireDate = new Date();
			const expireDate = response?.data?.data?.AuthenticationResult?.ExpiresIn;
			finalExpireDate.setSeconds(finalExpireDate.getSeconds() + expireDate);
			setCookie(
				"accessToken",
				response?.data?.data?.AuthenticationResult?.AccessToken,
				{
					expires: finalExpireDate,
				}
			);
		}
		return response?.data;
	}
);

export const redirectToPlay = createAsyncThunk(
	"redirect/play",
	async (eventCode: string | null, { getState }) => {
		const store: any = getState();
		if (eventCode) {
			localStorage.removeItem("email");
			localStorage.removeItem("phone");
			// removeCookie("eventCode");
			window.location.href = `${store.event?.eventVerfiyData?.guestURL}${eventCode}`;
		}
	}
);

export const confirmAccount = createAsyncThunk(
	"auth/confirmAccount",
	async (args: any, { rejectWithValue }) => {
		try {
			const { username, passCode, session } = args;
			const data = {
				username: username,
				passCode: passCode,
				session: session,
			};
			const config = {
				method: "POST",
				url: `${url}guest-verify-passwordless`,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				data: JSON.stringify(data),
			};
			const response = await axios(config);
			return response?.data;
		} catch (err: any) {
			throw rejectWithValue(err.response.status);
		}
	}
);

export const resendCode = createAsyncThunk(
	"auth/resendCode",
	async (args: any, { rejectWithValue }) => {
		try {
			const { userId, type } = args;
			const data = {
				userName: userId,
				type: type,
			};
			const config = {
				method: "POST",
				url: `${url}guest-resend-code`,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				data: JSON.stringify(data),
			};
			const response = await axios(config);
			return response?.data;
		} catch (err: any) {
			throw rejectWithValue(err.response.status);
		}
	}
);

export const clearMessage = createAsyncThunk("auth/clearMessage", async () => {
	const status = "";
	return status;
});

export const eventRelation = createAsyncThunk(
	"guest/verify",
	async (args: any, { dispatch }) => {
		// const { eventCode } = args;
		// const data = {
		//   eventCode: eventCode,
		// };
		const config = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: getCookie("accessToken"),
			},
			body: JSON.stringify(args),
		};
		try {
			const response = await fetch(`${url}guest-relation`, config);
			if (response?.status === 401) {
				if (process.env.REACT_APP_CUSTOM_NODE_ENV !== "production") {
					alert(
						`${getCookie("cookie1")}---------------${localStorage.getItem(
							"local1"
						)}---------------${sessionStorage.getItem(
							"session1"
						)}-----------------${getCookie(
							"refreshToken"
						)}-----------------${getCookie("accessToken")}`
					);
				}
				removeCookie("accessToken");
				removeCookie("refreshToken");
				removeCookie("session");
				window.location.href = `${window.location.origin}/session-expired`;
			} else if (response?.status === 403) {
				window.location.href = `${window.location.origin}/guest-limit-reached`;
			} else {
				const json = await response.json();
				sessionStorage.setItem("relationId", json?.data?.relationId);
				sessionStorage.setItem("productId", json?.data?.productId);
				return json;
			}
		} catch (err) {
			console.log(err);
		}
	}
);

export const verifyGuestSecurity = createAsyncThunk(
	"guest/security",
	async (args: any) => {
		const { eventCode, secretCode } = args;
		const config = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: getCookie("accessToken"),
			},
			body: JSON.stringify({ ...args }),
		};
		try {
			const response = await fetch(`${url}secret-code`, config);
			const json = await response.json();
			sessionStorage.setItem("relationId", json?.data?.relationId);
			return json;
		} catch (err) {
			console.log(err);
		}
	}
);

export const updateUserDetails = createAsyncThunk(
	"guest/update-user-details",
	async (args: any, { dispatch, getState }) => {
		const store: any = getState();
		const url = "my/v1/guest-my-info";
		const config: any = {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: getCookie("accessToken"),
			},
			body: JSON.stringify(args),
		};
		const response = await fetch(`${apiURL}${url}`, config);
		await response.json();
		return { ...args };
	}
);

export const eventSlice = createSlice({
	name: eventsSliceName,
	initialState,
	reducers: {
		setTncCheck(state, action) {
			state.tncCheck = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(eventVerify.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(eventVerify.rejected, (state) => {
			state.loading = false;
			state.responseStatus = "error";
		});
		builder.addCase(eventVerify.fulfilled, (state, action: any) => {
			state.loading = false;
			state.responseStatus = action.payload?.status;
			state.eventVerfiyData = action.payload?.data;
		});
		builder.addCase(eventRegister.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(eventRegister.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(eventRegister.fulfilled, (state, action) => {
			state.loading = false;
			state.eventRegisterData = action.payload.data;
		});
		builder.addCase(socialLoginSuccess.fulfilled, (state, action) => {
			state.accessToken = action.payload.token;
		});
		builder.addCase(confirmAccount.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(confirmAccount.rejected, (state, action: any) => {
			state.loading = false;
			state.verifyStatus = action.payload;
		});
		builder.addCase(confirmAccount.fulfilled, (state, action) => {
			state.loading = false;
			state.confirmationData = action.payload.data;
			state.verifyStatus = action.payload.status;
		});
		builder.addCase(resendCode.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(resendCode.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(resendCode.fulfilled, (state, action) => {
			state.loading = false;
			state.resendCodeStatus = action.payload.status;
		});
		builder.addCase(refreshAuthToken.pending, (state) => {
			state.secondLoading = true;
		});
		builder.addCase(refreshAuthToken.fulfilled, (state) => {
			state.secondLoading = false;
		});
		builder.addCase(refreshAuthToken.rejected, (state) => {
			state.secondLoading = false;
		});
		builder.addCase(clearMessage.fulfilled, (state) => {
			state.verifyStatus = 0;
		});
		builder.addCase(eventRelation.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(eventRelation.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(eventRelation.fulfilled, (state, action) => {
			state.loading = false;
			state.guestRelationId = action.payload?.data?.relationId;
		});
		builder.addCase(verifyGuestSecurity.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(verifyGuestSecurity.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(verifyGuestSecurity.fulfilled, (state) => {
			state.loading = false;
		});
	},
});
export const { setTncCheck } = eventSlice.actions;
export const eventReducer = eventSlice.reducer;
