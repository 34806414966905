import spotlightWelcomeBanner from "../src/assets/image/welcome_banner_spotlight.png";
import storybookWelcomeBanner from "../src/assets/image/storybookBanner.jpg";
import musicAppWelcomeBanner from "../src/assets/image/music-app-welcome-banner.png";
import cameraRollWelcomeBanner from "../src/assets/image/camera-roll-welcome-banner.png";
import seatingGenieWelcomeBanner from "../src/assets/image/seating-genie-welcome-banner.png";

import slideshowSignupBg from "../src/assets/image/signupBg.png";
import storybookSignupBg from "../src/assets/image/storybookSignupBg.png";
import musicAppSignupBg from "../src/assets/image/music-app-signup-bg.png";

export const appMapping = {
	storybook: {
		keyName: "storybook",
		productBanner: storybookWelcomeBanner,
		signupBg: storybookSignupBg,
		themeColor: "#099647",
	},
	slideshow: {
		keyName: "slideShow",
		productBanner: spotlightWelcomeBanner,
		signupBg: slideshowSignupBg,
		themeColor: "#ff7200",
	},
	songRequest: {
		keyName: "songRequest",
		productBanner: musicAppWelcomeBanner,
		signupBg: musicAppSignupBg,
		themeColor: "#3E5842",
	},
	cameraRoll: {
		keyName: "cameraRoll",
		productBanner: cameraRollWelcomeBanner,
		signupBg: cameraRollWelcomeBanner,
		themeColor: "#4BA9A9",
	},
	seatingchart: {
		keyName: "seatingchart",
		productBanner: seatingGenieWelcomeBanner,
		signupBg: seatingGenieWelcomeBanner,
		themeColor: "#0069b0",
	},
};
