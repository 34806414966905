import React from "react";
import {
	Link,
	Typography,
	Button,
	Box,
	Checkbox,
	FormControlLabel,
} from "@mui/material";
import { Container, styled } from "@mui/system";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { confirmAccount } from "../../redux/event";
import { LoginValidate } from "../validation";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../utils/Cookies";
import Cookies from "js-cookie";
import EventDetail from "../eventDetail";

const StyleContainer = styled(Container)`
	padding: 0px;
	.MuiCheckbox-root + .MuiFormControlLabel-label {
		font-size: 15px;
	}
	.MuiDivider-wrapper .MuiChip-root.MuiChip-outlined {
		border-radius: 100%;
		height: 30px;
		width: 30px;
	}
	.MuiDivider-wrapper .MuiChip-label {
		padding: 0px;
	}
	.MuiFormControlLabel-labelPlacementEnd {
		margin: 0px;
	}
	.errorMsg {
		color: red;
	}
	.loader {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		text-align: center;
		background-color: #00000050;
		z-index: 1111;
		align-items: center;
		justify-content: center;
	}
	label {
		font: normal normal normal 14px/18px Montserrat;
	}
`;

const FormBox = styled(Box)`
.form_field {
  border-radius: 4px;
  position: relative;
  background-color: theme.palette.mode=== 'light' ? '#fcfcfb' :'#2b2b2b';
  border: 1px solid #B5B5B5;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  padding: 0 12px;
  height: 48px;
  margin-top: 5px;
  outline: none;
},
`;

export const ConfirmAccount = () => {
	document.title = "Esstart.com > Guest Confirm Account";
	const dispatch = useDispatch<any>();
	const windowUrl = window.location.search;
	const params = new URLSearchParams(windowUrl);
	const eventCode: any = Cookies.get("eventCode");
	const confirmationCode = params.get("passCode");
	// const email = localStorage.getItem('guestEmail');
	const guestUsername = localStorage.getItem("username");
	const [isEmailPage, setIsEmailPage] = React.useState<Boolean>(false);
	// const [loadingTokens, setLoadingTokens] = React.useState<Boolean>(false);
	const navigate = useNavigate();
	const session = Cookies.get("session");
	const event: any = useSelector((state: any) => state.event);

	React.useEffect(() => {
		if (guestUsername && confirmationCode && session) {
			dispatch(
				confirmAccount({
					username: guestUsername,
					passCode: confirmationCode,
					session: session,
				})
			);
		} else {
			setIsEmailPage(true);
			// setLoadingTokens(false)
		}
	}, [eventCode, guestUsername, isEmailPage, confirmationCode, dispatch]);

	React.useEffect(() => {
		if (!confirmationCode) {
			navigate("/");
		}
		if (!session) {
			navigate(`/signup/${eventCode}`);
		}
	}, [confirmationCode, session]);

	const hanldeRedirect = () => {
		setCookie(
			"accessToken",
			event?.confirmationData?.AuthenticationResult?.AccessToken,
			{
				expires: event?.confirmationData?.AuthenticationResult?.ExpiresIn,
				// domain: getDomain()
			}
		);
		// dispatch(redirectToPlay(eventCode));
	};

	React.useEffect(() => {
		if (event?.verifyStatus && event?.verifyStatus !== 200) {
			window.location.href = `${window.location.origin}/error`;
			// navigate('/error')
		}
	}, [event?.verifyStatus]);

	React.useEffect(() => {
		if (
			eventCode &&
			event?.confirmationData?.AuthenticationResult?.AccessToken
		) {
			hanldeRedirect();
		}
	}, [event, eventCode]);

	const { handleChange, handleBlur, handleSubmit, values, errors, touched } =
		useFormik({
			initialValues: {
				email: "",
			},
			validate: LoginValidate,
			onSubmit: async (values: any) => {
				if (values.email && confirmationCode) {
					// setLoadingTokens(true);
					dispatch(
						confirmAccount({
							username: values.email,
							passCode: confirmationCode,
							session: session,
						})
					);
				}
			},
		});

	return (
		<StyleContainer>
			<EventDetail showHide={true}>
				{isEmailPage && (
					<>
						<FormBox>
							<form onSubmit={handleSubmit}>
								<label>Enter Email Address</label>
								<input
									className={`form_field ${
										errors.email && touched.email && "error"
									}`}
									onBlur={handleBlur}
									onChange={handleChange}
									type='email'
									placeholder='Enter Email Address'
									id='email'
									value={values.email}
								/>
								{errors.email && touched.email ? (
									<div className='errorMsg'>{errors.email as string}</div>
								) : null}

								<FormControlLabel
									control={
										<Checkbox
											style={{ padding: "0px 5px 0 0", color: "#009FB7" }}
											defaultChecked
										/>
									}
									label='Know more about our products and services.'
									style={{ padding: "20px 5px 0 0" }}
								/>

								<Button
									type='submit'
									variant='contained'
									style={{
										margin: "30px auto 0",
										display: "flex",
										background: "#FF7200",
										borderRadius: "20px",
										fontSize: 14,
										lineHeight: "18px",
										height: "40px",
										width: "198px",
										boxShadow: "none",
									}}
								>
									ENTER EVENT
								</Button>
							</form>
						</FormBox>
						<Box
							position={"absolute"}
							bottom={75}
							alignItems={"center"}
							width={"calc(100% - 32px)"}
						>
							<Typography
								textAlign='center'
								margin='0 auto'
								maxWidth='350px'
								fontSize='14px'
								lineHeight='18px'
								variant='body2'
								component='p'
							>
								By submitting this form, you are agreeing to our
								<Link
									href='https://www.esstart.com/tos'
									target='_blank'
									color='#009FB7'
									style={{ textDecoration: "none" }}
								>
									Terms and Conditions
								</Link>
							</Typography>
						</Box>
					</>
				)}
			</EventDetail>
		</StyleContainer>
	);
};
