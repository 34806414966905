import React from "react";

const PhoneIcon = (props: any) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='13.597' height='16.607' viewBox='0 0 13.597 16.607'>
		<g id='noun-sms-5299733' transform='translate(-127.946 -13.145)'>
			<path
				id='Path_510'
				data-name='Path 510'
				d='M129.536,13.145h7.319a1.6,1.6,0,0,1,1.59,1.59v.843h-1.184v-.843a.407.407,0,0,0-.406-.406h-7.319a.407.407,0,0,0-.406.406V26.32a.407.407,0,0,0,.406.406h7.319a.407.407,0,0,0,.406-.406V24.454h1.184v3.708a1.6,1.6,0,0,1-1.59,1.59h-7.319a1.593,1.593,0,0,1-1.59-1.59V14.735a1.592,1.592,0,0,1,1.59-1.59Zm2.887,2.967a1.383,1.383,0,0,0-1.379,1.379v5.049a1.383,1.383,0,0,0,1.379,1.379h.509l.458.794.664,1.151.666-1.151.458-.792h4.986a1.383,1.383,0,0,0,1.379-1.379V17.49a1.383,1.383,0,0,0-1.379-1.379Zm.272,5.863h7.2a.267.267,0,0,0,0-.534h-7.2a.267.267,0,0,0,0,.534Zm0-1.691h7.2a.267.267,0,0,0,0-.534h-7.2a.267.267,0,1,0,0,.534Zm0-1.693h7.2a.267.267,0,0,0,0-.534h-7.2a.267.267,0,1,0,0,.534Zm.5,8.918a.731.731,0,1,0,.731.731A.731.731,0,0,0,133.2,27.509Z'
				transform='translate(0)'
				fill={props.themeColor}
				fillRule='evenodd'
			/>
		</g>
	</svg>
);

export default PhoneIcon;
