import React from "react";
import { Container, styled } from "@mui/system";
import {
	Typography,
	Button,
	Divider,
	Box,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { SignupValidate } from "../validation";
import {
	eventRegister,
	eventRelation,
	redirectToPlay,
	updateUserDetails,
} from "../../redux/event";
import { getCookie } from "../../utils/Cookies";
import EventDetail from "../eventDetail";
import { Login } from "../login";
import { useNavigate } from "react-router-dom";
// import VerifyProcess from '../verifyProcess';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { appMapping } from "../../constants";

const FormBox = styled(Box)`
.form_field {
  border-radius: 4px;
  position: relative;
  background-color: theme.palette.mode=== 'light' ? '#fcfcfb' :'#2b2b2b';
  border: 1px solid #B5B5B5;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  padding: 0 12px;
  height: 48px;
  margin: 5px 0;
  outline: none;
}
`;

const StyleContainer = styled(Container)`
	padding: 0px;
	.MuiCheckbox-root + .MuiFormControlLabel-label {
		font-size: 15px;
	}
	.MuiDivider-wrapper .MuiChip-root.MuiChip-outlined {
		border-radius: 100%;
		height: 30px;
		width: 30px;
	}
	.MuiDivider-wrapper .MuiChip-label {
		padding: 0px;
	}
	.MuiFormControlLabel-labelPlacementEnd {
		margin: 0px;
	}
	.errorMsg {
		color: red;
	}
	label {
		font: normal normal normal 14px/18px Montserrat;
	}
	.title_phone_email {
		display: flex;
		align-items: center;
	}
	.title_phone_email p {
		font-size: 25px;
		margin: 0;
	}
	.title_phone_email svg {
		fill: #ff7200;
	}
	.anchor {
		color: #000;
	}
	.anchor:visited {
		color: #000;
	}
`;

const PhoneSignUp = () => {
	const dispatch = useDispatch<any>();
	const [phone, setPhone] = React.useState("");
	const [isClick, setClick] = React.useState(false);

	const event: any = useSelector((state: any) => state.event);
	const activityCode = getCookie("eventCode");
	const navigate = useNavigate();

	React.useEffect(() => {
		if (event?.eventRegisterData && isClick) {
			localStorage.setItem("guestEmail", phone);
			if (event?.eventRegisterData?.ChallengeName === "CUSTOM_CHALLENGE") {
				// OTP sent
				navigate("/verify");
			} else {
				// OTP bypassed
				if (values?.name) {
					dispatch(
						updateUserDetails({
							name: values?.name,
							...(!!event?.guestRelationId
								? { relationId: event?.guestRelationId }
								: {}),
						})
					);
				}
				(async () => {
					const data = await dispatch(
						eventRelation({ eventCode: activityCode })
					);
					if (data?.payload?.status === "success")
						dispatch(redirectToPlay(activityCode));
					else if (
						data.payload.message === "SECRET CODE REQUIRED" &&
						window.location.pathname !== "/verify-guest"
					)
						navigate("/verify-guest");
				})();
			}
		}
	}, [event?.eventRegisterData]);

	const getPhone = localStorage.getItem("phone");
	const {
		values,
		setFieldValue,
		handleChange,
		handleBlur,
		handleSubmit,
		errors,
		touched,
	} = useFormik({
		initialValues: {
			// phone: phone,
			phone: getPhone !== null || getPhone ? getPhone : "",
			name: localStorage.getItem("guestName") || "",
			tncAccepted: false,
		},
		validate: SignupValidate,
		enableReinitialize: true,
		onSubmit: async (values: any) => {
			localStorage.setItem("guestName", values?.name);
			setPhone(values?.phone);
			setClick(true);
			const username = values?.phone;
			const getUserName = localStorage.getItem("username");
			if (getUserName) {
				localStorage.removeItem("email");
			}
			localStorage.setItem("username", username);
			localStorage.setItem("phone", username);
			const res = await dispatch(
				eventRegister({ username: username, eventCode: activityCode })
			);
		},
	});

	//@ts-ignore
	const currentProductName = appMapping[event?.eventVerfiyData?.distinctName];

	return (
		<>
			<StyleContainer>
				<EventDetail showHide={false} loaderHideShow={false}>
					{/* {
            isClick && event?.loading ?
              <>
                <VerifyProcess />
              </> :
              <> */}
					<Typography component='div' className='title_phone_email'>
						{/* <KeyboardArrowLeftIcon /> */}
						<p>SMS Signup</p>
					</Typography>
					<Divider
						style={{ margin: "8px auto 14px auto", borderColor: "#D2D2D2" }}
					/>

					<form onSubmit={handleSubmit}>
						<FormBox className='mb-1'>
							<label>
								Enter Your Name<span className='color-red'>*</span>
							</label>
							<input
								className='form_field'
								onChange={handleChange}
								onBlur={handleBlur}
								type='text'
								placeholder='Enter Your Name'
								id='name'
								defaultValue={values?.name || ""}
							/>
							{errors.name && touched.name ? (
								<div className='errorMsg fs-14'>{errors.name as string}</div>
							) : null}
						</FormBox>
						<FormBox>
							<label>
								Enter Your Mobile Number<span className='color-red'>*</span>
							</label>
							<PhoneInput
								className='phone-input'
								id='phone'
								placeholder='555-555-5555'
								international
								defaultCountry={`${
									Intl.DateTimeFormat().resolvedOptions().timeZone ===
									"Asia/Calcutta"
										? "IN"
										: "US"
								}`}
								value={values.phone}
								countryCallingCodeEditable={false}
								onChange={(e) => {
									e ? setFieldValue("phone", e) : setFieldValue("phone", "");
								}}
							/>
							{errors.phone && touched.phone ? (
								<div className='errorMsg fs-14'>{errors.phone as string}</div>
							) : null}
						</FormBox>
						<Typography
							component='div'
							onClick={() => setFieldValue("tncAccepted", true)}
						>
							<p className='fs-12 m-0 mt-1'>
								You are enrolling into Esstart's Event Updates program. By
								providing the phone number, I authorize ESSTART.com and its
								service providers to contact me at the below mobile phone number
								via text (SMS) using automated dialing technology for customer
								service, product marketing and advertising purposes. Not a
								condition for purchase. Message and data rates may apply. I
								agree to{" "}
								<a
									className='anchor'
									href='https://www.esstart.com/tos'
									target='_blank'
									rel='noreferrer'
								>
									Terms of use
								</a>{" "}
								and{" "}
								<a
									href='https://www.esstart.com/p-policy'
									className='anchor'
									target='_blank'
									rel='noreferrer'
								>
									Privacy Policies
								</a>
								. To stop receiving messages, text “STOP” to +1877859208. For
								more information, text “HELP”.
							</p>
						</Typography>
						<FormControlLabel
							control={
								<Checkbox
									style={{ padding: "0px 5px 0 0", color: "#009FB7" }}
									onChange={handleChange}
									checked={values.tncAccepted}
									name='tncAccepted'
								/>
							}
							label={
								<p className='m-0'>
									Sign up for text (SMS) Alerts
									<span className='color-red'>*</span>
								</p>
							}
							style={{ padding: "8px 5px 0 0" }}
						/>
						{errors.tncAccepted && touched.tncAccepted ? (
							<div className='errorMsg fs-14'>
								{errors.tncAccepted as string}
							</div>
						) : null}
						<Button
							type='submit'
							variant='contained'
							style={{
								margin: "20px auto 35px auto",
								display: "flex",
								background: currentProductName?.themeColor,
								borderRadius: "20px",
								fontSize: 14,
								lineHeight: "18px",
								height: "40px",
								width: "198px",
								boxShadow: "none",
							}}
						>
							PROCEED
						</Button>
					</form>
					<Login isPhone={true} />
					{/* </>
          } */}
				</EventDetail>
			</StyleContainer>
		</>
	);
};

export default PhoneSignUp;
