import React from "react";
import { Container, styled } from "@mui/system";
import { Typography, Button, Divider, Box } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { SignupValidate } from "../validation";
import {
	eventRegister,
	eventRelation,
	redirectToPlay,
	updateUserDetails,
} from "../../redux/event";
import EventDetail from "../eventDetail";
import { Login } from "../login";
import { getCookie } from "../../utils/Cookies";
import { useNavigate } from "react-router-dom";
import { appMapping } from "../../constants";

const FormBox = styled(Box)`
.form_field {
  border-radius: 4px;
  position: relative;
  background-color: theme.palette.mode=== 'light' ? '#fcfcfb' :'#2b2b2b';
  border: 1px solid #B5B5B5;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  padding: 0 12px;
  height: 48px;
  margin: 5px 0;
  outline: none;
}
`;

const StyleContainer = styled(Container)`
	padding: 0px;
	.MuiCheckbox-root + .MuiFormControlLabel-label {
		font-size: 15px;
	}
	.MuiDivider-wrapper .MuiChip-root.MuiChip-outlined {
		border-radius: 100%;
		height: 30px;
		width: 30px;
	}
	.MuiDivider-wrapper .MuiChip-label {
		padding: 0px;
	}
	.MuiFormControlLabel-labelPlacementEnd {
		margin: 0px;
	}
	.errorMsg {
		color: red;
	}
	label {
		font: normal normal normal 14px/18px Montserrat;
	}
	.title_phone_email {
		display: flex;
		align-items: center;
	}
	.title_phone_email p {
		font-size: 25px;
		margin: 0;
	}
	.title_phone_email svg {
		fill: #ff7200;
	}

	.title_phone_email p {
		font-size: 25px;
		margin: 0;
		font-weight: 500;
	}
`;

const EmailSignUp = () => {
	const dispatch = useDispatch<any>();
	const event: any = useSelector((state: any) => state.event);
	const getEmail = localStorage.getItem("email");

	const [email, setEmail] = React.useState(getEmail ? getEmail : "");
	const [isClick, setClick] = React.useState(false);
	const navigate = useNavigate();
	const activityCode = getCookie("eventCode");

	React.useEffect(() => {
		if (event?.eventRegisterData && isClick) {
			localStorage.setItem("guestEmail", email);
			if (event?.eventRegisterData?.ChallengeName === "CUSTOM_CHALLENGE") {
				//otp sent
				navigate("/verify");
			} else {
				// OTP bypassed
				if (values?.name) {
					dispatch(
						updateUserDetails({
							name: values?.name,
							...(!!event?.guestRelationId
								? { relationId: event?.guestRelationId }
								: {}),
						})
					);
				}
				(async () => {
					const data = await dispatch(
						eventRelation({ eventCode: activityCode })
					);
					if (data?.payload?.status === "success")
						dispatch(redirectToPlay(activityCode));
					else if (
						data.payload.message === "SECRET CODE REQUIRED" &&
						window.location.pathname !== "/verify-guest"
					)
						navigate("/verify-guest");
				})();
			}
		}
	}, [event?.eventRegisterData]);

	const { handleChange, handleBlur, handleSubmit, errors, touched, values } =
		useFormik({
			initialValues: {
				email: email,
				name: localStorage.getItem("guestName") || "",
			},
			validate: SignupValidate,
			onSubmit: async (values: any) => {
				localStorage.setItem("guestName", values?.name);
				const username = values?.email?.trim();
				setEmail(values?.email?.trim());
				setClick(true);
				const getUserName = localStorage.getItem("username");
				if (getUserName) {
					localStorage.removeItem("phone");
				}
				localStorage.setItem("username", username);
				localStorage.setItem("email", username);
				const res = await dispatch(
					eventRegister({ username: username, eventCode: activityCode })
				);
			},
		});

	//@ts-ignore
	const currentProductName = appMapping[event?.eventVerfiyData?.distinctName];

	return (
		<>
			<StyleContainer>
				<EventDetail showHide={false} loaderHideShow={false}>
					<Typography component='div' className='title_phone_email'>
						{/* <KeyboardArrowLeftIcon /> */}
						<p>Email Login</p>
					</Typography>
					<Divider
						style={{
							margin: "15px auto 30px auto",
							borderColor: "#D2D2D2",
						}}
					/>
					<form className='email-form' onSubmit={handleSubmit}>
						<FormBox className='mb-1'>
							<label>
								Enter Name<span className='color-red'>*</span>
							</label>
							<input
								className='form_field'
								onChange={handleChange}
								onBlur={handleBlur}
								type='text'
								placeholder='Enter Name'
								id='name'
								defaultValue={values?.name || ""}
							/>
							{errors.name && touched.name ? (
								<div className='errorMsg'>{errors.name as string}</div>
							) : null}
						</FormBox>
						<FormBox>
							<label>
								Enter Email Address<span className='color-red'>*</span>
							</label>
							<input
								className='form_field'
								onChange={handleChange}
								onBlur={handleBlur}
								type='email'
								placeholder='Enter Email Address'
								id='email'
								defaultValue={getEmail ? getEmail : ""}
							/>
							{errors.email && touched.email ? (
								<div className='errorMsg'>{errors.email as string}</div>
							) : null}
						</FormBox>
						<Button
							type='submit'
							variant='contained'
							style={{
								margin: "20px auto 35px auto",
								display: "flex",
								background: currentProductName?.themeColor,
								borderRadius: "20px",
								fontSize: 14,
								lineHeight: "18px",
								height: "40px",
								width: "198px",
								boxShadow: "none",
							}}
						>
							PROCEED
						</Button>
					</form>
					<Login isEmail={true} />
				</EventDetail>
			</StyleContainer>
		</>
	);
};

export default EmailSignUp;
