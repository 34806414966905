import React from "react";
import { Outlet } from "react-router-dom";

const DashboardWrapper = () => {

  return (
    <>
      <Outlet />
    </>
  )
}

export default DashboardWrapper;
