import React from "react";
import { Grid, Link } from "@mui/material";
// Image
import SiteLogo from "../assets/image/esstart-logo.svg";

export const Header = () => {
  return (
    <>
      <Grid container spacing={0} alignItems='center' paddingY={2} paddingX={2}>
        <Grid item xs={6}>
          <Link
            href={process.env.REACT_APP_DEV_WEBSITE_URL_LIVE}
            target='_blank'
            rel='noopener noreferrer'
            display='inline-block'
          >
            <img src={SiteLogo} alt='Site Logo' width='190px' />
          </Link>
        </Grid>
        <Grid item xs={6} textAlign='end'>
          {/* <Link href={process.env.REACT_APP_DEV_WEBSITE_URL_LIVE} display="inline-block" style={{ fontSize: "19px", lineHeight: "23px", color: "#202020", textDecoration: "none", cursor: "pointer" }}>Home</Link> */}
        </Grid>
      </Grid>
    </>
  );
};
