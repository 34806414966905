import React from "react";
import { Box, Grid } from "@mui/material";
import { Container, styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { Login } from "../login";
import { setTncCheck } from "../../redux/event";
import { Footer } from "../footer";
import "./style.css";
import { appMapping } from "../../constants";
import Loader from "../loader";

const StyleContainer = styled(Container)`
	padding: 0px;
	.MuiCheckbox-root + .MuiFormControlLabel-label {
		font-size: 15px;
	}
	.MuiDivider-wrapper .MuiChip-root.MuiChip-outlined {
		border-radius: 100%;
		height: 30px;
		width: 30px;
	}
	.MuiDivider-wrapper .MuiChip-label {
		padding: 0px;
	}
	.MuiFormControlLabel-labelPlacementEnd {
		margin: 0px;
	}
	.errorMsg {
		color: red;
	}

	label {
		font: normal normal normal 14px/18px Montserrat;
	}

	.title_phone_email {
		display: flex;
		align-items: center;
	}
	.title_phone_email p {
		font-size: 25px;
		margin: 0;
	}
	.title_phone_email svg {
		fill: #ff7200;
	}

	.tnc-check {
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 0.5rem;

		input {
			accent-color: #009fb7;
			width: 1.2rem;
			height: 1.2rem;
		}
	}
	.app-footer {
		bottom: -84px;
		max-width: 480px;
	}
`;

export const Signup = () => {
	document.title = `Esstart.com > Guest Signup`;
	sessionStorage.setItem("gallery", "no");
	const dispatch = useDispatch();
	const { tncCheck, eventVerfiyData, loading, secondLoading } = useSelector(
		(store: any) => store?.event
	);
	//@ts-ignore
	const currentProduct = appMapping[eventVerfiyData?.distinctName];

	return (
		<StyleContainer>
			{(loading || secondLoading) && <Loader />}
			<Grid container spacing={0} justifyContent='center'>
				<Grid item xs={12} sm={8} md={5}>
					<div
						className='signup-bg-container'
						style={{
							backgroundImage: `url(${currentProduct?.signupBg})`,
						}}
					>
						<div className='login-options-container text-center'>
							<div
								className='lock-icon-wrapper'
								style={{
									backgroundColor: currentProduct?.themeColor,
								}}
							>
								<div className='lock-icon'></div>
							</div>
							<div className='title'>
								<p className='color-red'>Event is Private</p>
								<p>Please login to access this event</p>
							</div>
							<h1 className='show-name text-center'>
								{eventVerfiyData?.slideShow?.showName}
							</h1>
							<Login />
						</div>
					</div>
					<Box position={"absolute"} bottom={-36} alignItems={"center"}>
						<label className='tnc-check'>
							<input
								type='checkbox'
								defaultChecked={tncCheck}
								onChange={(e) => dispatch(setTncCheck(e.target.checked))}
							/>
							<span>To continue, please accept the Terms and Conditions.</span>
						</label>
					</Box>
					<Footer />
				</Grid>
			</Grid>
		</StyleContainer>
	);
};
