import React from "react";
import DashboardWrapper from "../view/Dashboard/DashboardWrapper";
import { Home } from "../components/home";
import { Signup } from "../components/signup";
import { ConfirmAccount } from "../components/confirmAccount";
import { SocialLoginSuccess } from "../components/socialLoginSuccess";
import { RouteInterceptor } from "./routeInterceptor";
import { PageNotFound } from "../components/pageNotFound";
import { Error } from "../components/error";
import { GuestCountLimitError } from "../components/error";
import PhoneSignUp from "../components/phoneSignup";
import EmailSignUp from "../components/emailSignup";
import VerifyOtp from "../components/verifyOtp";
import { SessionExpired } from "../components/sessionExpired/SessionExpired";
import VerifyUser from "../components/verifyUser";
import { WelcomeScreen } from "../components/welcomeScreen";
import { Tradeshow5Aug24 } from "../components/tradeshow5aug24";

export const routes = [
	{
		path: "/",
		element: <DashboardWrapper />,
		routes: [
			{
				path: "",
				index: true,
				element: <Home />,
			},
			{
				path: "event/:id",
				index: true,
				element: <Home />,
			},
			{
				path: "event",
				index: true,
				element: <Home />,
			},
			{
				path: "welcome/:id",
				index: true,
				element: (
					<RouteInterceptor>
						<WelcomeScreen />
					</RouteInterceptor>
				),
			},
			{
				path: "login/:id",
				index: true,
				element: (
					<RouteInterceptor>
						<Signup />
					</RouteInterceptor>
				),
			},
			{
				path: "signup/:id",
				index: true,
				element: (
					<RouteInterceptor>
						<Signup />
					</RouteInterceptor>
				),
			},
			{
				path: "phone-signup/:id",
				index: true,
				element: (
					<RouteInterceptor>
						<PhoneSignUp />
					</RouteInterceptor>
				),
			},
			{
				path: "email-signup/:id",
				index: true,
				element: (
					<RouteInterceptor>
						<EmailSignUp />
					</RouteInterceptor>
				),
			},
			{
				path: "verify/:id",
				index: true,
				element: (
					<RouteInterceptor>
						<VerifyOtp />
					</RouteInterceptor>
				),
			},
			{
				path: "verify",
				index: true,
				element: (
					<RouteInterceptor>
						<VerifyOtp />
					</RouteInterceptor>
				),
			},
			{
				path: "confirm-guest",
				index: true,
				element: (
					<RouteInterceptor>
						<ConfirmAccount />
					</RouteInterceptor>
				),
			},
			{
				path: "verify-guest",
				index: true,
				element: (
					// <RouteInterceptor>
					<VerifyUser />
					// </RouteInterceptor>
				),
			},
			{
				path: "guest-social-login-success",
				index: true,
				element: (
					<RouteInterceptor>
						<SocialLoginSuccess />
					</RouteInterceptor>
				),
			},
			{
				path: "guest-limit-reached",
				index: true,
				element: <GuestCountLimitError />,
			},
			{
				path: "tradeshow-lucky-draw",
				index: true,
				element: <Tradeshow5Aug24 />,
			},
			{
				path: "error",
				index: true,
				element: <Error />,
			},
			{
				path: "session-expired",
				index: true,
				element: <SessionExpired />,
			},
			{
				path: "*",
				index: true,
				element: <PageNotFound />,
			},
		],
	},
];
