import React from "react";
import "./style.css";

const Loader = () => {
	return (
		<div className='loader esstart-loader'>
			<svg
				id='Layer_1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 99.17 96.92'
				width='99.16999816894531'
				height='96.91999816894531'
			>
				<defs>
					<style>
						{
							"\n      .cls-1{fill:#63cbe7;}.cls-2{fill:#efdf18;}.cls-3{fill:#fdb814;}.cls-4{fill:#f15a22;}.cls-5{fill:#27b14b;}.cls-6{fill:#9887bf;}\n    "
						}
					</style>
				</defs>
				<path
					className='cls-5 svg-elem-1'
					d='m29.5,3.36c1.5-1.51,3.37-.34,4.62.87,1.89,1.83,3.87,3.56,5.76,5.39,1.9,1.83,3.55,3.93,5.61,5.58,3.18,2.55,6.73,1.73,9.86-.37,3.44-2.32,6.98-4.53,10.75-6.28,1.95-.9,3.91-1.75,5.9-2.55,1.24-.49,2.89-.8,3.95.24.24.23.43.52.58.82.81,1.65-.96,3.3-2.05,4.31-1.43,1.32-3,2.46-4.55,3.63-1.23.93-2.41,1.9-3.63,2.82s-2.43,1.8-3.66,2.68c-1.37.99-2.73,2.02-4,3.15-.97.87-2.32,1.9-2.61,3.23-.44,2.01-.5,4.12-.64,6.17-.13,1.89.04,4.19-1.12,5.82-1.62,2.27-4.99,1.48-7.02.3-2.67-1.55-4.84-4.2-6.03-7.04-.77-1.83-1.18-3.8-1.36-5.77-.17-1.97.39-4.02-.19-5.95-.5-1.65-1.53-3.07-2.62-4.38-1.69-2.01-3.39-4-4.94-6.12-.84-1.15-1.71-2.28-2.46-3.49-.3-.48-.57-1.04-.63-1.61-.06-.55.11-1.06.5-1.45Z'
				></path>
				<circle
					className='cls-5 svg-elem-2'
					cx='49.49'
					cy='6.26'
					r='6.26'
				></circle>
				<path
					className='cls-1 svg-elem-3'
					d='m78.46,8.88c2.06.55,1.97,2.75,1.55,4.44-.64,2.55-1.16,5.13-1.8,7.68-.64,2.55-1.64,5.03-2.04,7.64-.62,4.03,1.85,6.7,5.23,8.36,3.73,1.83,7.4,3.8,10.8,6.2,1.75,1.24,3.47,2.52,5.15,3.85,1.05.83,2.13,2.1,1.76,3.54-.08.32-.23.63-.42.91-1.03,1.52-3.34.81-4.76.36-1.85-.58-3.63-1.38-5.42-2.14-1.41-.6-2.84-1.14-4.25-1.75s-2.77-1.22-4.15-1.84c-1.54-.7-3.11-1.37-4.72-1.9-1.23-.41-2.8-1.06-4.1-.65-1.96.62-3.82,1.61-5.67,2.51-1.7.83-3.62,2.12-5.6,1.92-2.77-.28-3.77-3.59-3.75-5.94.02-3.09,1.23-6.29,3.1-8.73,1.21-1.58,2.7-2.92,4.33-4.05,1.62-1.13,3.68-1.66,5.06-3.13,1.19-1.26,1.9-2.85,2.49-4.46.9-2.47,1.78-4.93,2.85-7.33.58-1.3,1.12-2.62,1.8-3.87.27-.5.62-1.01,1.08-1.35.44-.33.97-.43,1.5-.29Z'
				></path>
				<circle
					className='cls-1 svg-elem-4'
					cx='85.9'
					cy='27.66'
					r='6.26'
				></circle>
				<path
					className='cls-6 svg-elem-5'
					d='m98.49,54.32c.53,2.06-1.43,3.07-3.11,3.52-2.54.69-5.04,1.5-7.58,2.19-2.54.69-5.19,1.03-7.67,1.96-3.82,1.43-4.94,4.89-4.73,8.65.23,4.15.31,8.32-.12,12.45-.22,2.13-.5,4.26-.84,6.37-.21,1.32-.79,2.89-2.22,3.27-.32.08-.66.11-1,.08-1.83-.15-2.34-2.51-2.65-3.97-.4-1.9-.57-3.84-.79-5.77-.17-1.53-.39-3.04-.56-4.56s-.3-3.01-.43-4.52c-.15-1.69-.33-3.38-.66-5.04-.25-1.28-.44-2.96-1.44-3.9-1.5-1.41-3.28-2.54-4.97-3.71-1.56-1.08-3.62-2.12-4.42-3.95-1.12-2.55,1.29-5.04,3.34-6.18,2.7-1.5,6.09-2.01,9.13-1.57,1.97.28,3.87.93,5.65,1.79,1.78.86,3.25,2.39,5.21,2.88,1.68.42,3.42.26,5.1,0,2.59-.42,5.17-.86,7.79-1.11,1.42-.13,2.83-.3,4.26-.33.57,0,1.18.05,1.7.28.5.23.85.64.99,1.17Z'
				></path>
				<circle
					className='cls-6 svg-elem-6'
					cx='85.76'
					cy='70'
					r='6.26'
				></circle>
				<path
					className='cls-3 svg-elem-7'
					d='m69.21,93.93c-1.53,1.48-3.37.28-4.6-.95-1.86-1.86-3.8-3.64-5.66-5.49-1.86-1.86-3.47-3.99-5.5-5.68-3.14-2.61-6.7-1.86-9.86.18-3.49,2.25-7.07,4.4-10.87,6.07-1.96.86-3.95,1.68-5.95,2.43-1.25.47-2.9.75-3.94-.31-.23-.24-.42-.52-.56-.83-.78-1.66,1.02-3.28,2.14-4.27,1.45-1.29,3.05-2.4,4.62-3.54,1.24-.9,2.44-1.85,3.69-2.75s2.47-1.75,3.71-2.61c1.39-.96,2.77-1.96,4.05-3.07.98-.85,2.35-1.85,2.67-3.18.48-2,.58-4.11.76-6.15.16-1.89.04-4.19,1.23-5.8,1.66-2.24,5.02-1.38,7.02-.17,2.64,1.6,4.76,4.29,5.89,7.15.73,1.85,1.11,3.82,1.25,5.8.13,1.97-.47,4.01.08,5.95.47,1.66,1.47,3.1,2.54,4.43,1.65,2.04,3.31,4.06,4.83,6.21.82,1.16,1.67,2.31,2.39,3.53.29.49.55,1.05.6,1.62.05.55-.13,1.06-.52,1.44Z'
				></path>
				<circle
					className='cls-3 svg-elem-8'
					cx='49.28'
					cy='90.66'
					r='6.26'
				></circle>
				<path
					className='cls-2 svg-elem-9'
					d='m20.13,88.51c-2.05-.58-1.93-2.78-1.48-4.46.68-2.54,1.24-5.11,1.91-7.65.68-2.55,1.71-5.01,2.15-7.61.68-4.02-1.75-6.73-5.11-8.44-3.7-1.89-7.35-3.91-10.71-6.36-1.73-1.26-3.43-2.57-5.09-3.93-1.03-.85-2.1-2.13-1.71-3.56.09-.32.24-.63.43-.9,1.05-1.5,3.35-.76,4.77-.3,1.85.61,3.61,1.43,5.39,2.22,1.41.62,2.83,1.18,4.23,1.81,1.38.62,2.75,1.25,4.12,1.9,1.53.72,3.09,1.41,4.69,1.97,1.23.42,2.78,1.1,4.09.71,1.97-.59,3.85-1.56,5.7-2.43,1.72-.81,3.65-2.07,5.63-1.84,2.77.32,3.71,3.65,3.67,5.99-.06,3.09-1.32,6.27-3.23,8.69-1.23,1.56-2.75,2.88-4.39,3.99-1.64,1.1-3.7,1.61-5.11,3.05-1.2,1.24-1.94,2.82-2.55,4.42-.94,2.46-1.85,4.9-2.95,7.29-.59,1.29-1.16,2.6-1.85,3.84-.28.5-.63,1-1.1,1.33-.45.32-.98.42-1.51.27Z'
				></path>
				<circle
					className='cls-2 svg-elem-10'
					cx='12.96'
					cy='69.63'
					r='6.26'
				></circle>
				<path
					className='cls-4 svg-elem-11'
					d='m.09,43.06c-.52-2.06,1.45-3.06,3.13-3.51,2.54-.68,5.05-1.47,7.59-2.15,2.54-.68,5.2-1.01,7.67-1.92,3.83-1.41,4.96-4.87,4.77-8.63-.21-4.15-.27-8.32.18-12.45.23-2.13.52-4.26.87-6.37.22-1.32.8-2.89,2.24-3.26.32-.08.66-.1,1-.07,1.83.16,2.33,2.52,2.63,3.98.39,1.9.56,3.84.76,5.77.16,1.53.38,3.04.54,4.57s.28,3.01.41,4.52c.14,1.69.31,3.38.63,5.05.24,1.28.43,2.96,1.42,3.9,1.5,1.42,3.27,2.56,4.95,3.73,1.55,1.09,3.61,2.13,4.4,3.97,1.1,2.56-1.31,5.04-3.37,6.17-2.71,1.49-6.1,1.98-9.14,1.53-1.97-.29-3.86-.95-5.64-1.82-1.77-.87-3.24-2.41-5.19-2.91-1.67-.43-3.42-.28-5.1-.01-2.6.41-5.17.83-7.79,1.07-1.42.13-2.83.29-4.26.31-.57,0-1.18-.05-1.7-.29-.5-.23-.85-.64-.98-1.17Z'
				></path>
				<circle
					className='cls-4 svg-elem-12'
					cx='12.89'
					cy='27.44'
					r='6.26'
				></circle>
			</svg>
		</div>
	);
};

export default Loader;
