import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import ReactGA from "react-ga4";
import { getCookie } from "./utils/Cookies";
import * as Sentry from "@sentry/react";

// ReactGA.initialize([
//   {
//     trackingId: String(process.env.REACT_APP_GA_MEASUREMENT_ID),
//     gaOptions: { userId: getCookie("_ga") as string },
//   },
// ]);
// ReactGA.send({
//   hitType: "pageview",
//   page: window.location.pathname + window.location.search,
// });
if (process.env.REACT_APP_CUSTOM_NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://db11d3796e09406da83bd7da4bf58aa3@o4505155920986112.ingest.sentry.io/4505165344538624",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    maxValueLength: 10000,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
