import React from 'react';
import { Grid, Box } from '@mui/material';
import { Container, styled } from '@mui/system';
import { Footer } from '../footer';
import { Header } from '../header';

const PageWrapper = styled(Container)`
padding: 0px;
text-align: cwenter;
`;
export const PageNotFound = () => {
  return (
    <PageWrapper>
      <Grid container spacing={0} justifyContent="center">
        <Grid item xs={12} sm={8} md={5}>
          <Header />
          <Box position="relative" style={{ minHeight: "calc(100vh - 75px)" }}>
            <Box sx={{ paddingBottom: "130px", textAlign: 'center' }} paddingX={2}>
              <h4 className='heading'>404 PAGE NOT FOUND !!</h4>
              <Footer />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </PageWrapper>
  )
}