import React from "react";
import { Footer } from "../footer";
import { Header } from "../header";
import { Grid, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
// import Loader from '../../assets/image/Esstart_spinner.gif';
import Loader from "../loader";
import { styled } from "@mui/system";

interface IProps {
	children: any;
	showHide: boolean;
	loaderHideShow?: boolean;
}

const StyleContainer = styled(Box)`
	.loader {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		text-align: center;
		background-color: #00000050;
		z-index: 1111;
		align-items: center;
		justify-content: center;
	}
	.loader img {
		width: 100px;
	}
`;
const EventDetail = ({ children, showHide, loaderHideShow }: IProps) => {
	const event: any = useSelector((state: any) => state.event);
	return (
		<>
			<StyleContainer>
				{/* {!loaderHideShow && (
          <>
            <Typography
              style={{ display: event.loading ? 'flex' : 'none' }}
              className='loader'
              component='div'
            >
              <img src={Loader} alt='Loader' />
            </Typography>
          </>
        )} */}
				{!loaderHideShow && (event.loading || event.secondLoading) && (
					<Loader />
				)}
				<Grid container spacing={0} justifyContent='center'>
					<Grid item xs={12} sm={8} md={5}>
						<Header />
						<Box
							position='relative'
							style={{ minHeight: "calc(100vh - 75px)" }}
						>
							<Box sx={{ paddingBottom: "130px" }} paddingX={2}>
								{showHide && (
									<>
										<img
											src={
												event?.eventVerfiyData &&
												event?.eventVerfiyData[
													Object.keys(event?.eventVerfiyData).filter(
														(keyName) =>
															keyName !== "guestURL" &&
															keyName !== "distinctName"
													)[0]
												]?.bannerImgPresignedUrl
											}
											alt='Hero Banner'
											width='100%'
										/>

										<Typography margin='10px 0' variant='body2' component='p'>
											Hi, Welcome to
										</Typography>
										<Typography
											variant='subtitle1'
											fontWeight={600}
											component='h2'
											margin='0 0 10px'
										>
											{event?.eventVerfiyData &&
												event?.eventVerfiyData[
													Object.keys(event?.eventVerfiyData).filter(
														(keyName) =>
															keyName !== "guestURL" &&
															keyName !== "distinctName"
													)[0]
												]?.showName}
										</Typography>
									</>
								)}

								{children}
							</Box>
							<Footer />
						</Box>
					</Grid>
				</Grid>
			</StyleContainer>
		</>
	);
};

export default EventDetail;
