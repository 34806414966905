import Cookies from "js-cookie";

// export const getDomain = () => {
//   const host = window.location.hostname;
//   const domainParts = host.split('.') || [];
//   domainParts.shift();
//   let domain = '.' + domainParts.join('.');
//   if (host.includes('localhost')) {
//     domain = 'localhost';
//   } else {
//     Cookies.set('domain', domain);
//   }
//   return domain;
// }

export const SetAuthCookie = (data: any) => {
	if (data) {
		// const domain = getDomain();

		const finalExpireDate = new Date();
		const expireDate = data?.ExpiresIn;
		finalExpireDate.setSeconds(finalExpireDate.getSeconds() + expireDate);

		setCookie("accessToken", data?.AccessToken, {
			expires: finalExpireDate,
		});

		const future = new Date();
		future.setDate(future.getDate() + 30);
		setCookie("refreshToken", data?.RefreshToken, { expires: future });
		setCookie("username", data?.username, { expires: future });
	}
};

export const getCookie = (key: string) => {
	return Cookies.get(key) || "";
};

export const removeCookie = (key: string) => {
	const expiresIn = Cookies.get("expires");
	const domain = Cookies.get("domain");
	if (domain) {
		Cookies.remove(key, { expires: Number(expiresIn), domain });
	} else {
		Cookies.remove(key, { expires: Number(expiresIn) });
	}
	Cookies.remove(key);
};

export const setCookie = (key: string, value: string, options?: any) => {
	if (key && value) {
		Cookies.set(key, value, { ...options });
	}
	// Cookies.set(key, value, { ...options, domain: getDomain()});
};
