import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Container, styled } from "@mui/system";
import { Grid, Box, Button } from "@mui/material";
import EventDetail from "../eventDetail";

const StyleContainer = styled(Container)`
padding: 0px;
  .error_page {
    width: 100%;
    height: calc(100vh - 220px);
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    padding: 1rem;
  }
  .error_page p {
    text-align: center;
    font-size: 32px;
    width: 100%;
    margin: 0;
  }
  .error_desc {
    font-size: 16px !important;
  }
  .error_page button {
    background: #FF7200;
    color: #fff;
    font-size: 14px;
    text-align: center;
    border-radius: 20px;
    margin: 42px auto 0 auto;
    padding: 11px 42px;
  }
}
`;

export const Error = () => {
	const navigate = useNavigate();

	const removeCookie = () => {
		Cookies.remove("session");
		// Cookies.remove('eventCode')
		Cookies.remove("accessToken");
		Cookies.remove("refrshToken");
	};

	const handleBackHome = () => {
		removeCookie();
		navigate("/");
	};

	setTimeout(() => {
		removeCookie();
		navigate("/");
	}, 5000);

	return (
		<>
			<StyleContainer>
				<Grid container spacing={0} justifyContent='center'>
					<Grid item xs={12} sm={8} md={5}>
						<Box className='error_page'>
							<p>Something Went</p>
							<p style={{ marginBottom: "21px" }}>WRONG !</p>
							<p className='error_desc' style={{ marginBottom: "15px" }}>
								We did not find a session token on your device, retry sign and
								please use the same browser
							</p>
							<p className='error_desc'>We suggest you back to Home</p>
							<Button onClick={handleBackHome}>BACK TO HOME</Button>
						</Box>
					</Grid>
				</Grid>
			</StyleContainer>
		</>
	);
};

export const GuestCountLimitError = () => {
	const navigate = useNavigate();
	return (
		<>
			<EventDetail showHide={false}>
				<StyleContainer>
					<Grid container spacing={0} justifyContent='center'>
						<Grid item xs={12} sm={8} md={5}>
							<Box className='error_page'>
								<p style={{ marginBottom: "21px" }}>
									Sorry, you cannot enter this event.
								</p>
								<p className='error_desc' style={{ marginBottom: "15px" }}>
									The guest limit for this event has been reached. To continue,
									please encourage the event host to purchase the event from
									ESSTART for unrestricted access.
								</p>
								<Button onClick={() => navigate("/")}>BACK TO HOME</Button>
							</Box>
						</Grid>
					</Grid>
				</StyleContainer>
			</EventDetail>
		</>
	);
};
